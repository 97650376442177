import React from "react";
import { Grid, Card, Table, Button, Page, Badge, Dropdown } from "tabler-react";
import Widget from "./Reports/Widget";
import RecordSalesForm from "./SalesForm/RecordSalesForm";
import Receipt from "./Receipt";
// import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
// import formatMoney from "../../MoneyFormatter";
import { softDelete, put } from "../../httpUtils";
import { showConfirmation, showModal } from "../../Platform/events";
import AmountDisplay from "../../Platform/AmountDisplay";
import { isAdmin } from "../../authHelper";
import { registerGlobalModal } from "../../Platform/ModalManager";
import PaginatedTable from "../../Platform/PaginatedTable";
import moment from "moment";

registerGlobalModal("RecordSalesForm", RecordSalesForm);
registerGlobalModal("Receipt", Receipt);
function SalesDashboard(props) {
  let renderRow = (i) => {
    return (
      <React.Fragment>
        <Table.Col>{i.receiptNo}</Table.Col>
        <Table.Col>{moment(i.created).format("MMM Do YYYY")}</Table.Col>
        <Table.Col>{i.customer && i.customer.name}</Table.Col>
        <Table.Col>{i.store && i.store.name}</Table.Col>
        <Table.Col>
          <AmountDisplay amount={i.total}></AmountDisplay>
        </Table.Col>
        <Table.Col>
          <AmountDisplay amount={i.amountReceived}></AmountDisplay>
        </Table.Col>
        <Table.Col>
          <AmountDisplay amount={i.total - i.amountReceived}></AmountDisplay>
        </Table.Col>
        <Table.Col>
          {i.paid && (
            <Badge color="success" className="mr-1">
              Paid
            </Badge>
          )}
          {!i.paid && (
            <Badge color="danger" className="mr-1">
              Not Paid
            </Badge>
          )}
        </Table.Col>

        <Table.Col>
          <Dropdown
            trigger={<Dropdown.Trigger icon="more-horizontal" toggle={false} />}
            position="right"
            triggerContent="Actions"
            itemsObject={[
              ...showMarkAsPaid(i),
              ...showDeleteButton(i),
              {
                value: "Receipt",

                icon: "fe fe-eye",
                onClick: function () {
                  showModal("Receipt", { selected: i });
                },
              },
            ]}
          />
        </Table.Col>
      </React.Fragment>
    );
  };
  return (
    <Page.Content className="page-content" title="Sales">
      <Grid>
        <Grid.Row>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Recent sales</Card.Title>
                <Card.Options>
                  <Button
                    color="primary"
                    size="md"
                    onClick={() => {
                      showModal("RecordSalesForm");
                    }}
                  >
                    <i className="fe fe-plus-circle mr-2"></i> Record Sale
                  </Button>
                  <Widget />
                </Card.Options>
              </Card.Header>
              <PaginatedTable
                columns={[
                  "Receipt No.",
                  "Date",
                  "Customer",
                  "Store",
                  "Total amount",
                  "Amount Paid",
                  "Amount Due",
                  "Status",
                  "",
                ]}
                url="/inventory/sales"
                refetchEvents={["sold-item-created"]}
                renderRow={renderRow}
              />
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Page.Content>
  );
}

function showMarkAsPaid(item) {
  if (item.paid) {
    return [];
  }
  return [
    {
      value: "Mark as paid",
      icon: "fe fe-check-square",
      onClick: function () {
        put(`/inventory/sales/${item._id}/mark-as-paid`, item);
      },
    },
    { isDivider: true },
  ];
}
function showDeleteButton(item) {
  if (isAdmin())
    return [
      {
        value: "Delete",

        icon: "fe fe-trash-2",
        onClick: function () {
          showConfirmation({
            text: "Are you sure you want to delete purchase?",
            onConfirm() {
              softDelete(`/inventory/sales/${item._id}`);
            },
          });
        },
      },
      { isDivider: true },
    ];
  return [];
}
export default SalesDashboard;
