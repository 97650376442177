import React from "react";
import { Card, Table, Button, Page } from "tabler-react";
import fetchCategories from "./fetchCategories";
import { softDelete } from "../../httpUtils";
import { showModal, showConfirmation } from "../../Platform/events";
import { isAdmin } from "../../authHelper";

function CategoriesList() {
  const { loading, results } = fetchCategories();

  return (
    <Page.Content className="page-content" title="Categories">
      <Card>
        <Card.Header>
          <Card.Title>Product Categories</Card.Title>
          <Card.Options>
            <Button
              color="primary"
              size="md"
              onClick={() => showModal("CategoriesForm", null)}
            >
              <i className="fe fe-plus-circle mr-2"></i> Add new category
            </Button>
          </Card.Options>
        </Card.Header>

        <Table
          responsive
          className="card-table table-vcenter text-nowrap"
          headerItems={[
            { content: "Item category Name" },
            { content: "Description" },
            {},
          ]}
        >
          <Table.Body>
            {loading && !results.length && (
              <Table.Row>
                <Table.Col colSpan="5">Loading...</Table.Col>
              </Table.Row>
            )}
            {!loading && !results.length && (
              <Table.Row>
                <Table.Col colSpan="5">No results found</Table.Col>
              </Table.Row>
            )}
            {results.map((i) => (
              <Table.Row>
                <Table.Col>{i.category}</Table.Col>
                <Table.Col>{i.description}</Table.Col>
                <Table.Col>
                  {isAdmin() && (
                    <Button
                      color="danger"
                      icon="fe fe-trash-2"
                      className="ml-2 pull-right"
                      size="sm"
                      onClick={() => {
                        showConfirmation({
                          text: `Are you sure you want to delete ${i.category}?`,
                          onConfirm() {
                            softDelete(`/inventory/store/category/${i._id}`);
                          },
                        });
                      }}
                    ></Button>
                  )}{" "}
                  <Button
                    className="ml-2 pull-right"
                    color="secondary"
                    icon="fe fe-edit"
                    size="sm"
                    onClick={() => showModal("CategoriesForm", { item: i })}
                  >
                    Edit
                  </Button>
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </Page.Content>
  );
}

export default CategoriesList;
