import React from "react";
import HomeCardContent from "./HomeCardContent";
const ModuleDisabled = (props) => {
  return (
    // eslint-disable-next-line
    <a title="module is disabled" style={{ cursor: "not-allowed" }}>
      <div style={{ opacity: 0.1 }}>
        <HomeCardContent {...props} />
      </div>
    </a>
  );
};

export default ModuleDisabled;
