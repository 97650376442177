import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
function useFetchStoreItems(additionalEvents = [], offset = 0, limit = 10) {
  console.log("ex");
  const { error, loading, results } = useDataFetchWithPusher(
    `/inventory/store/items?offset=${offset}&limit=${limit}`,
    [...additionalEvents, "store-item-created"],
    offset
  );
  return {
    error,
    loading,
    results,
  };
}
export default useFetchStoreItems;
