import React from "react";
import { Grid, Card, Table, Button } from "tabler-react";
// import NewCustomerForm from "./NewCustomerForm";
import IndividualCustomerReport from "./Reports/IndividualCustomerReport";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
// import fetchCustomer from "./fetchCustomers";
import { softDelete } from "../httpUtils";
import { showModal, showConfirmation } from "../Platform/events";
import { isAdmin } from "../authHelper";

function CustomerList(props) {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [
    individualCustomerReportModalShow,
    setIndividualCustomerReportModalShow,
  ] = React.useState(false);
  const { loading, results: customers } = useDataFetchWithPusher("/kyc", [
    "customer-created",
  ]);
  return (
    <Grid.Row>
      <IndividualCustomerReport
        customer={selectedItem}
        show={individualCustomerReportModalShow}
        onHide={() => {
          setIndividualCustomerReportModalShow(false);
        }}
      />
      <Grid.Col width={12}>
        <Card>
          <Card.Header>
            <Card.Title>Customer List</Card.Title>
            <Card.Options>
              <Button
                color="primary"
                size="md"
                onClick={() => showModal("CustomerForm", null)}
              >
                Add new customer
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Name" },
              { content: "Address" },
              { content: "E-mail" },
              { content: "Tel" },
              {},
            ]}
          >
            <Table.Body>
              {loading && !customers.length && (
                <Table.Row>
                  <Table.Col colSpan="6">Loading...</Table.Col>
                </Table.Row>
              )}
              {!loading && !customers.length && (
                <Table.Row>
                  <Table.Col colSpan="6">No results found</Table.Col>
                </Table.Row>
              )}
              {customers.map((i) => (
                <Table.Row>
                  <Table.Col>{i.name}</Table.Col>
                  <Table.Col>{i.address}</Table.Col>
                  <Table.Col>{i.email}</Table.Col>
                  <Table.Col>{i.tel}</Table.Col>
                  <Table.Col>
                    {isAdmin() && (
                      <Button
                        color="danger"
                        icon="fe fe-trash-2"
                        className="ml-2 pull-right"
                        size="sm"
                        onClick={() => {
                          showConfirmation({
                            text: `Are you sure you want to delete ${i.name}?`,
                            onConfirm() {
                              softDelete(`/kyc/${i._id}`);
                            },
                          });
                        }}
                      ></Button>
                    )}
                    <Button
                      color="secondary"
                      icon="fe fe-eye"
                      className="ml-2 pull-right"
                      size="sm"
                      onClick={() => {
                        setSelectedItem(i);
                        setIndividualCustomerReportModalShow(true);
                      }}
                    >
                      History
                    </Button>{" "}
                    <Button
                      color="secondary"
                      icon="fe fe-edit"
                      className="ml-2 pull-right"
                      size="sm"
                      onClick={() => showModal("CustomerForm", { customer: i })}
                    >
                      Edit
                    </Button>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      </Grid.Col>
    </Grid.Row>
  );
}

export default CustomerList;
