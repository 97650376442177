import React from "react";
import { formatNoCurrency } from "../../MoneyFormatter";
const CurrentStock = ({ product, store }) => {
  return (
    <React.Fragment>
      {`${formatNoCurrency(getCurrentStock(store, product.currentStock))} ${
        product.unit
      }`}
    </React.Fragment>
  );
};

export default CurrentStock;

export function getCurrentStock(storeId, currentStock) {
  if (!storeId) {
    return currentStock.reduce((acc, curr) => {
      if (curr) {
        acc = curr.count + acc;
      }
      return acc;
    }, 0);
  }
  const store = currentStock.find((ele) => ele && ele.store === storeId);
  return store ? store.count : 0;
}
