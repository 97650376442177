function saveUser(user) {
  localStorage.setItem("auth", JSON.stringify(user));
}

function getToken() {
  return getUser().token;
}
function getUser() {
  if (isAuthenticated()) return JSON.parse(localStorage.getItem("auth"));
}
function isSuperAdmin() {
  if (isAuthenticated() && getUser().type === "super-admin") {
    return true;
  }
}
function isCooperativeAdmin() {
  if (isAuthenticated() && getUser().type === "admin") {
    return true;
  }
}
function isAdmin() {
  if (isCooperativeAdmin() || isSuperAdmin()) {
    return true;
  }
}
function isAuthenticated() {
  return localStorage.getItem("auth");
}

function logout() {
  localStorage.clear();
}

export {
  saveUser,
  getToken,
  getUser,
  isAuthenticated,
  logout,
  isSuperAdmin,
  isCooperativeAdmin,
  isAdmin,
};
