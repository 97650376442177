import moment from "moment";
import React from "react";
import { Button, Card, Page, Table } from "tabler-react";
import { showModal } from "../../Platform/events";
import PaginatedTable from "../../Platform/PaginatedTable";

function TransfersList() {
  let renderRow = (i) => {
    return (
      <React.Fragment>
        <Table.Col>{moment(i.created).format("MMM Do YYYY")}</Table.Col>
        <Table.Col>{i.item.name}</Table.Col>
        <Table.Col>{i.fromStore.name}</Table.Col>
        <Table.Col>{i.toStore.name}</Table.Col>
        <Table.Col>{i.numberOfItems}</Table.Col>
      </React.Fragment>
    );
  };
  return (
    <Page.Content className="page-content" title="Stock Transfer">
      <Card>
        <Card.Header>
          <Card.Title>Stock Transfer List</Card.Title>
          <Card.Options>
            <Button
              className="ml-2 pull-right"
              color="primary"
              icon="fe fe-git-pull-request"
              size="md"
              onClick={() => showModal("TransferStockForm", null)}
            >
              Transfer Stock
            </Button>
          </Card.Options>
        </Card.Header>
        <PaginatedTable
          columns={["Date", "Item", "From", "To", "Quantity"]}
          url="/inventory/store/transfer-stock"
          refetchEvents={["transfer-updated", "transfer-created"]}
          renderRow={renderRow}
        />
      </Card>
    </Page.Content>
  );
}

export default TransfersList;
