import React, { useState, useEffect } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import useForm from "../../effects/useForm";
import { post, put } from "../../httpUtils";
import fetchDepartments from "../Departments/fetchDepartments";
import fetchEmployees from "./fetchEmployees";
import useNumberFetch from "../../effects/useNumberFetch";
import { useToasts } from "react-toast-notifications";

const EmployeeForm = (props) => {
  const { item } = props;
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  let { results: departments } = fetchDepartments();
  const { results: employees } = fetchEmployees();
  const employeeId = item?._id;
  const filteredEmployees = employees.filter(
    (employee) => employee._id !== employeeId
  );
  const { handleChange, handleSubmit, manualChange, values, setInitialValues } =
    useForm(submit);
  const isEdit =
    typeof item !== "undefined" && item !== null && Object.keys(item).length;
  const currentItemValues = isEdit ? values : item || {};

  useEffect(() => {
    if (item) {
      const { birthdate, ...itemValues } = item;
      const date = birthdate.split("T")[0];
      console.log("Item: >>", item);
      const newItem = {
        ...itemValues,
        birthdate: date,
        department: item.department._id,
      };
      setInitialValues(newItem);
    }
    //eslint-disable-next-line
  }, [item]);
  const { results: nextNumber } = useNumberFetch("EM");
  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/hrm`, formValues);
      } else {
        await put(`/hrm/${item._id}`, formValues);
      }
      addToast("Employee added Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!item || item.fullname === undefined
              ? "Add new employee"
              : `Edit ${item.fullname}`}
          </Modal.Title>{" "}
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert key={error} type="danger">
              {error}
            </Alert>
          ))}
          <Form.FieldSet className="NewItem">
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Fullname">
                    <Form.Input
                      name="fullname"
                      placeholder="Enter employee full name"
                      type="text"
                      onChange={handleChange}
                      value={currentItemValues.fullname}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Birthdate">
                    <Form.Input
                      name="birthdate"
                      placeholder="Enter employee date of birth"
                      type="date"
                      onChange={handleChange}
                      value={currentItemValues.birthdate}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Photo">
                    <Form.FileInput
                      name="avatar"
                      type="file"
                      accept="image/jpg, image/gif"
                      onChange={(e) => {
                        let files = e.target.files;

                        let file = files[0];
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          const event = {
                            persist() {},
                            target: {
                              name: "avatar",
                              value: reader.result,
                            },
                          };
                          handleChange(event);
                        };
                      }}
                    ></Form.FileInput>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Gender"
                    name="gender"
                    onChange={handleChange}
                    value={currentItemValues.gender}
                  >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Schedule"
                    name="schedule"
                    onChange={handleChange}
                    value={currentItemValues.schedule}
                  >
                    <option>Select Schedule</option>
                    <option value="9-17">9am - 5pm</option>
                    <option value="18-06">6pm - 6am</option>
                  </Form.Select>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Department"
                    name="department"
                    onChange={handleChange}
                    value={currentItemValues.department}
                  >
                    <option>Select Department</option>
                    {departments.map((i) => (
                      <option key={i._id} value={i._id}>
                        {i.name}
                      </option>
                    ))}
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Next of kin">
                    <Form.Input
                      placeholder="Enter next of kin name"
                      name="nextOfKin"
                      type="string"
                      onChange={handleChange}
                      value={currentItemValues.nextOfKin}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Next of kin contact">
                    <Form.Input
                      placeholder="Enter next of kin telephone number"
                      name="nextOfKinContact"
                      type="number"
                      onChange={handleChange}
                      value={currentItemValues.nextOfKinContact}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Bank name">
                    <Form.Input
                      name="bankName"
                      placeholder="Enter employee bank name"
                      type="text"
                      onChange={handleChange}
                      value={currentItemValues.bankName}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Bank account number">
                    <Form.Input
                      name="accountNumber"
                      placeholder="Enter employee bank account number"
                      type="string"
                      onChange={handleChange}
                      accept="image/jpg, image/gif"
                      value={currentItemValues.accountNumber}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="TIN">
                    <Form.Input
                      placeholder="Enter employee Tax Identification Number (TIN)"
                      name="tin"
                      type="number"
                      onChange={handleChange}
                      value={currentItemValues.tin}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="NSSF number">
                    <Form.Input
                      name="nssf"
                      placeholder="Enter employee NSSF number"
                      type="number"
                      onChange={handleChange}
                      value={currentItemValues.nssf}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Employee no">
                    <Form.Input
                      name="employeeNo"
                      type="text"
                      onChange={handleChange}
                      value={
                        !item || item.fullname === undefined
                          ? nextNumber.number
                          : currentItemValues.employeeNo
                      }
                      disabled
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Contract Type"
                    name="contractType"
                    onChange={handleChange}
                    value={currentItemValues.contractType}
                  >
                    <option>Select Contract Type</option>
                    <option value="temporary">Temporary</option>
                    <option value="fixed">Fixed</option>
                    <option value="open-ended">Open ended</option>
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Supervisor">
                    <Select
                      name="employee"
                      placeholder="Select employee"
                      searchBy="employee"
                      valueField="_id"
                      labelField="fullname"
                      defaultValues={""}
                      options={filteredEmployees}
                      onChange={(values) => {
                        if (values.length) {
                          manualChange({
                            name: "supervisor",
                            value: values[0]._id,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Employee Contact Number">
                    <Form.Input
                      name="contactNumber"
                      placeholder="Enter employee contact number"
                      type="text"
                      onChange={handleChange}
                      value={currentItemValues.contactNumber}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Address">
                    <Form.Input
                      name="address"
                      placeholder="Enter employee area of residence"
                      type="text"
                      onChange={handleChange}
                      value={currentItemValues.address}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!item || !item.fullname
              ? `Add new employee`
              : `Update ${item.fullname}`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EmployeeForm;
