import React, { useEffect, useState } from "react";
import eventEmitter from "./eventEmitter";
import ConfirmationModal from "./ConfirmationModal";

let modalComponentLookupTable = {
  ConfirmationModal,
};
const ModalManager = () => {
  const [modals, updateModals] = useState([]);
  const [hidden, setHiddenModal] = useState(null);
  useEffect(() => {
    eventEmitter.on("showModal", (args) => {
      updateModals((modals) => [...modals, args]);
    });
    return function cleanup() {
      eventEmitter.removeListener("showModal");
    };
  }, []);
  function hideModal(modal, i) {
    setHiddenModal(i);
    setTimeout(() => {
      const newModals = modals.slice();
      newModals.pop();
      updateModals(newModals);
      setHiddenModal(null);
    }, 300);
  }
  const opacity = {
    opacity: 0.1,
  };
  return (
    <React.Fragment>
      {modals.map((modalArgs, i) => {
        const { modalType, props } = modalArgs;
        const ModalComponent = modalComponentLookupTable[modalType];
        return (
          <div style={modals.length - 1 === i ? null : opacity}>
            <ModalComponent
              key="ModalComponent"
              {...props}
              show={!(i === hidden)}
              onHide={() => {
                hideModal(modalArgs, i);
              }}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
};
export function registerGlobalModal(name, modal) {
  modalComponentLookupTable = { ...modalComponentLookupTable, [name]: modal };
}
export function registerGlobalModals(modals) {}
export default ModalManager;
