import React, { useState } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../../effects/useForm";
import fetchStores from "../../Store/fetchStores";
import { post } from "../../../httpUtils";
import SalesItems from "./SalesItems";
import fetchCustomers from "../../../Kyc/fetchCustomers";
import useNumberFetch from "../../../effects/useNumberFetch";
import SelectWithAdd from "../../../Platform/SelectWithAdd";
import { useToasts } from "react-toast-notifications";

function RecordSaleForm(props) {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit, manualChange, values } = useForm(submit);
  const { results: customers } = fetchCustomers();
  const { results: stores } = fetchStores();
  const { results: nextNumber } = useNumberFetch("SO");

  async function submit(data) {
    //validate
    try {
      await post("/inventory/sales", data);
      addToast("Sale Recorded Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  function handleItemsChange(values, total) {
    manualChange({ name: "items", value: values });
    manualChange({ name: "total", value: total });
  }
  return (
    <Modal
      {...props}
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Register new sale</Modal.Title>{" "}
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}

          <Grid>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Receipt Number">
                    <Form.Input
                      name="receiptNo"
                      value={nextNumber.number}
                      onChange={handleChange}
                      disabled
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Payment Type"
                    name="paymentType"
                    onChange={handleChange}
                  >
                    <option>Select Payment Type</option>
                    <option value="invoice">Invoice</option>
                    <option value="cash">Cash</option>
                    <option value="partial">Partial payment</option>
                    <option value="mobile-money">Mobile money</option>
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Store"
                    name="store"
                    onChange={handleChange}
                  >
                    <option>Select Store</option>
                    {stores.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Grid.Col>
                <Grid.Col width={6}>
                  <SelectWithAdd
                    onChange={handleChange}
                    label="Customer"
                    name="customer"
                    options={customers.map((i) => ({
                      value: i._id,
                      display: i.name,
                    }))}
                    defaultText="Select Customer"
                    modalType="CustomerForm"
                  />
                </Grid.Col>
              </Grid.Row>
            </div>
            <SalesItems onChange={handleItemsChange} store={values.store} />
            <Grid.Row>
              <Grid.Col width={6}></Grid.Col>
              <Grid.Col width={6}>
                <Form.Group label="Amount Received">
                  <Form.Input name="amountReceived" onChange={handleChange} />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="align-content-end"
            variant="outline-danger"
            onClick={props.onHide}
          >
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            Register sale
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RecordSaleForm;
