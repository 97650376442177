import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
function useFetchCooperative(additionalEvents = []) {
  const { error, loading, results } = useDataFetchWithPusher(
    "/users/cooperatives?offset=0&&limit=1000",
    [...additionalEvents, "cooperative-created"]
  );

  return {
    error,
    loading,
    results: results.items || [],
  };
}
export default useFetchCooperative;
