import React from "react";
import Select from "react-dropdown-select";
import { Form } from "tabler-react";
const SearchableSelect = ({ label, onChange, defaultText, options }) => {
  return (
    <Form.Group label={label}>
      <Select
        placeholder={defaultText}
        searchBy="display"
        valueField="value"
        labelField="display"
        options={options || []}
        onChange={(selected) => {
          onChange(selected[0]);
        }}
      />
    </Form.Group>
  );
};

export default SearchableSelect;
