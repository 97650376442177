// @flow

import * as React from "react";
import { Site, Grid, List } from "tabler-react";
import { NavLink, withRouter } from "react-router-dom";
import { isAuthenticated, getUser, isSuperAdmin } from "./authHelper";
import getUserType from "./Utils/AccountTranslator";
import ModalManager from "./Platform/ModalManager";

const navBarItems = {
  default: [
    {
      value: "Home",
      to: "/home",
      icon: "home",
      LinkComponent: withRouter(NavLink),
    },
  ],
  hrm: [
    {
      value: "Home",
      to: "/home",
      icon: "home",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Employees",
      to: "/hrm",
      icon: "users",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Leave",
      to: "/hrm/leave",
      icon: "user-x",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Payroll",
      to: "/hrm/payroll",
      icon: "dollar-sign",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Attendance",
      to: "/hrm/attendance",
      icon: "watch",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Performance",
      to: "/hrm/performance",
      icon: "trending-up",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Departments",
      to: "/hrm/departments",
      icon: "grid",
      LinkComponent: withRouter(NavLink),
    },
  ],
  kyc: [
    {
      value: "Home",
      to: "/home",
      icon: "home",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Kyc",
      to: "/kyc",
      icon: "fe fe-user-plus",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Transactions",
      to: "/kyc/transactions",
      icon: "fe fe-file-text",
      LinkComponent: withRouter(NavLink),
    },
  ],
  crm: [
    {
      value: "Home",
      to: "/home",
      icon: "home",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Queries",
      to: "/crm",
      icon: "fe fe-message-circle",
      LinkComponent: withRouter(NavLink),
    },
  ],
  users: [
    {
      value: "Home",
      to: "/home",
      icon: "home",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Users",
      to: "/users",
      icon: "fe fe-users",
      LinkComponent: withRouter(NavLink),
    },
  ],
  inventory: [
    {
      value: "Home",
      to: "/home",
      icon: "home",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Inventory",
      to: "/inventory",
      icon: "codepen",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Products",
      to: "/inventory/store",
      icon: "box",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Purchases",
      to: "/inventory/purchases",
      icon: "corner-down-right",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Sales",
      to: "/inventory/sales",
      icon: "corner-left-up",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Suppliers",
      to: "/inventory/suppliers",
      icon: "fe fe-truck",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Stores",
      to: "/inventory/manage-stores",
      icon: "fe fe-clipboard",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Categories",
      icon: "fe fe-layers",
      to: "/inventory/categories",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Returns Out",
      to: "/inventory/damages",
      icon: "fe fe-alert-circle",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Returns In",
      to: "/inventory/returns",
      icon: "fe fe-alert-triangle",
      LinkComponent: withRouter(NavLink),
    },
    {
      value: "Stock Transfer",
      to: "/inventory/transfers",
      icon: "fe fe-git-pull-request",
      LinkComponent: withRouter(NavLink),
    },
  ],
};

const accountDropdownProps = {
  avatarURL: "/demo/faces/male/2.jpg",
  name: "Staff Member",
  description: getUser() && getUserType(getUser().type),
  options: [
    {
      icon: "log-out",
      value: "Sign out",
      to: "/",
      LinkComponent: withRouter(NavLink),
    },
  ],
};

class SiteWrapper extends React.Component {
  state = {
    notificationsObjects: [
     
    ],
  };
  render() {
    if (isAuthenticated()) {
      accountDropdownProps.name = getUser().fullname;
      accountDropdownProps.avatarURL = getUser().avatar;
    }
    const notificationsObjects = this.state.notificationsObjects || [];
    const unreadCount = this.state.notificationsObjects.reduce(
      (a, v) => a || v.unread,
      false
    );
    let menuItems = navBarItems[this.props.module] || navBarItems["default"];

    if (this.props.module === "users" && isSuperAdmin()) {
      menuItems = [
        ...menuItems,
        {
          value: "Cooperatives",
          to: "/users/cooperative",
          icon: "fe fe-link",
          LinkComponent: withRouter(NavLink),
        },
      ];
    }

    return (
      <Site.Wrapper
        headerProps={{
          href: "/home",
          alt: "Splendor",
          imageURL: "/images/logo.png",
          notificationsTray: {
            notificationsObjects,
            markAllAsRead: () =>
              this.setState(
                () => ({
                  notificationsObjects: this.state.notificationsObjects.map(
                    (v) => ({ ...v, unread: false })
                  ),
                }),
                () =>
                  setTimeout(
                    () =>
                      this.setState({
                        notificationsObjects:
                          this.state.notificationsObjects.map((v) => ({
                            ...v,
                            unread: true,
                          })),
                      }),
                    5000
                  )
              ),
            unread: unreadCount,
          },
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: menuItems }}
        footerProps={{
          note: "",
          copyright: (
            <React.Fragment>
              Copyright © 2020
              <a href="http://www.uhuruinstitute.org/"> Uhuru Institute </a>
              All rights reserved.
            </React.Fragment>
          ),
          nav: (
            <React.Fragment>
              <Grid.Col auto={true}>
                <List className="list-inline list-inline-dots mb-0">
                  <List.Item className="list-inline-item">
                    <a href="/">FAQ</a>
                  </List.Item>
                </List>
              </Grid.Col>
            </React.Fragment>
          ),
        }}
      >
        <ModalManager />
        {this.props.children}
      </Site.Wrapper>
    );
  }
}

export default SiteWrapper;
