import React from "react";
import { Avatar, Grid } from "tabler-react";
import moment from "moment";
const Profile = ({ profile }) => {
  console.log("profile :>> ", profile);
  const {
    avatar,
    employeeNo = "N/A",
    fullname,
    department = "N/A",
    gender,
    contactNumber = "N/A",
    address = "N/A",
    birthdate,
    bankName = "N/A",
    accountNumber = "N/A",
    tin = "N/A",
    nssf = "N/A",
    supervisor = "N/A",
    contractType = "N/A",
    nextOfKin = "N/A",
    nextOfKinContact = "N/A",
  } = profile;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col width={2}>
          <div style={{ paddingTop: 10 }}>
            <Avatar size="xxl" imageURL={avatar} />
          </div>
        </Grid.Col>
        <Grid.Col>
          <div style={{ paddingRight: 10 }}>
            <table className="table card-table table-vcenter text-nowrap">
              <tr>
                <th>Employee No</th>
                <td>{employeeNo}</td>
              </tr>
              <tr>
                <th>Name</th>
                <td>{fullname}</td>
              </tr>
              <tr>
                <th>Department</th>
                <td style={{ textTransform: "uppercase" }}>
                  {department.name}
                </td>
              </tr>
              <tr>
                <th>Gender</th>
                <td style={{ textTransform: "capitalize" }}>{gender}</td>
              </tr>
              <tr>
                <th>Contact Number</th>
                <td>{contactNumber}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{address}</td>
              </tr>
              <tr>
                <th>DOB</th>
                <td> {moment(birthdate).format("MMM Do YYYY")}</td>
              </tr>
              <tr>
                <th>Bank Name</th>
                <td>{bankName}</td>
              </tr>
              <tr>
                <th>Account Number</th>
                <td>{accountNumber}</td>
              </tr>
              <tr>
                <th>TIN</th>
                <td>{tin}</td>
              </tr>
              <tr>
                <th>NSSF</th>
                <td>{nssf}</td>
              </tr>
              <tr>
                <th>Supervisor</th>
                <td>
                  {supervisor !== "N/A" ? supervisor.fullname : supervisor}
                </td>
              </tr>
              <tr>
                <th>Contract Type</th>
                <td style={{ textTransform: "capitalize" }}>{contractType}</td>
              </tr>
              <tr>
                <th>Next Of Kin</th>
                <td>{nextOfKin}</td>
              </tr>
              <tr>
                <th>Next Of Kin Contact</th>
                <td>{nextOfKinContact}</td>
              </tr>
              {getTerminatedButton(profile)}
            </table>
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
function getTerminatedButton(item) {
  if (item.terminated.status === true) {
    return (
      <tr>
        <th>Reason for Termination</th>
        <td>{item.terminated.reason}</td>
      </tr>
    );
  }
  return;
}

export default Profile;
