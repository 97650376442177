import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button, Table, Form, Avatar } from "tabler-react";
import ReactToPrint from "react-to-print";
import useDataFetchWithPusher from "../../../effects/useDataFetchWithPusher";
import fetchStores from "../../Store/fetchStores";
import { getUser } from "../../../authHelper";
const PurchaseBySupplier = (props) => {
  const { cooperative } = getUser();
  const { results: stores } = fetchStores();
  const [store, setActiveStore] = useState(null);
  const componentRef = useRef();
  const { results: purchases } = useDataFetchWithPusher(
    `/inventory/purchases/required/${store}`,
    [],
    store
  );
  useEffect(() => {
    if (stores.length) {
      setActiveStore(stores[0]._id);
    }
  }, [stores]);
  function getStoreName() {
    if (stores.length) {
      let storeObj = stores.find((s) => s._id === store);
      if (storeObj) {
        return storeObj.name;
      }
    }
  }
  return (
    <Modal
      {...props}
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <div className="well col-md-4">
          <Form.Select
            name="store"
            onChange={(e) => {
              setActiveStore(e.target.value);
            }}
          >
            {stores.map((item) => (
              <option value={item._id}>{item.name}</option>
            ))}
          </Form.Select>
        </div>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" icon="printer">
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row printDiv" ref={componentRef}>
          <div className="well col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <div>
                    <Avatar
                      size="xxl"
                      imageURL={cooperative.logo}
                      alt="Cooperative logo"
                    />
                  </div>
                  <h2>Purchases required</h2>
                  <h3>{getStoreName()}</h3>
                </div>
                <br />
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                  headerItems={[
                    { content: "Item" },
                    { content: "Supplier" },
                    { content: "" },
                    { content: "" },
                  ]}
                >
                  <Table.Body>
                    {purchases.items &&
                      purchases.items.map((pr) => (
                        <Table.Row>
                          <Table.Col>{pr.name}</Table.Col>
                          <Table.Col colSpan={3}>
                            {pr.supplier && pr.supplier.companyName}
                          </Table.Col>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" icon="x-circle" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PurchaseBySupplier;
