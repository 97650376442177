import React, { useState, useEffect } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
import { post, put } from "../../httpUtils";
import { useToasts } from "react-toast-notifications";

const PerformanceForm = (props) => {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit, values, setInitialValues } =
    useForm(submit);
  const { question } = props;
  useEffect(() => {
    if (
      typeof question !== "undefined" &&
      question !== null &&
      Object.keys(question).length
    ) {
      setInitialValues(question);
    }
    //eslint-disable-next-line
  }, [question]);

  const isEdit =
    typeof question !== "undefined" &&
    question !== null &&
    Object.keys(question).length;
  const currentQuestionValues = isEdit ? values : question || {};
  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/hrm/performance/questions`, formValues);
      } else {
        await put(`/hrm/performance/questions/${question._id}`, formValues);
      }
      addToast("Question Added Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!question || question.question === undefined
              ? "Add new question"
              : "Edit question"}
          </Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet className="Add performance assessment question">
            <Grid>
              <Form.Select
                label="Type"
                name="type"
                value={currentQuestionValues.type}
                onChange={handleChange}
              >
                <option>Select question type</option>
                <option value="score">Score based</option>
                <option value="text">Free text</option>
              </Form.Select>
              {values?.type === "score" ? (
                <Form.Group label="Target">
                  <Form.Input
                    name="target"
                    type="number"
                    min="0"
                    max="100"
                    onChange={handleChange}
                    value={currentQuestionValues.target}
                  />
                </Form.Group>
              ) : (
                ""
              )}
              <Form.Group>
                <Form.Textarea
                  label="Question"
                  name="question"
                  placeholder="Content.."
                  rows={4}
                  onChange={handleChange}
                  value={currentQuestionValues.question}
                />
              </Form.Group>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            {!question || question.question === undefined
              ? "Add question"
              : "Edit question"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PerformanceForm;
