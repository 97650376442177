import React from "react";
import { Grid, Card, Table, Button, Page, Dropdown } from "tabler-react";
import LeaveForm from "./LeaveForm";
import { softDelete, put } from "../../httpUtils";
import { formatDateForDisplay, dateDifferenceFromToday } from "../../DateUtils";
import { showConfirmation, showModal } from "../../Platform/events";
// import { isAdmin } from "../../authHelper";
import StatusBadge from "./StatusBadge";
import PaginatedTable from "../../Platform/PaginatedTable";
import { registerGlobalModal } from "../../Platform/ModalManager";

registerGlobalModal("LeaveForm", LeaveForm);
function Leave(props) {
  let renderRow = (i) => {
    return (
      <React.Fragment>
        <Table.Col>{i.employee.employeeNo}</Table.Col>
        <Table.Col>{i.employee.fullname}</Table.Col>
        <Table.Col>{formatDateForDisplay(i.from)}</Table.Col>
        <Table.Col>{formatDateForDisplay(i.to)}</Table.Col>
        <Table.Col>{`${dateDifferenceFromToday(i.to)} days`}</Table.Col>
        <Table.Col>
          <StatusBadge status={i.status} />
        </Table.Col>
        <Table.Col>
          <Dropdown
            trigger={<Dropdown.Trigger icon="more-horizontal" toggle={false} />}
            position="right"
            triggerContent="Actions"
            itemsObject={getDropDownItems(i)}
          />
        </Table.Col>
        <Table.Col>
          <Button
            color="danger"
            icon="fe fe-trash-2"
            size="sm"
            className="ml-2 pull-right"
            onClick={() => {
              showConfirmation({
                text: `Are you sure you want to delete ${i.fullname}'s Leave?`,
                onConfirm() {
                  softDelete(`/hrm/leave/${i._id}`);
                },
              });
            }}
          ></Button>
        </Table.Col>
      </React.Fragment>
    );
  };
  return (
    <Page.Content className="page-content" title="Leave">
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>Leave sheet</Card.Title>
              <Card.Options>
                <Button
                  color="primary"
                  size="md"
                  onClick={() => showModal("LeaveForm", null)}
                >
                  <i className="fe fe-plus-circle mr-2"></i> Record leave
                </Button>
              </Card.Options>
            </Card.Header>
            <PaginatedTable
              columns={[
                "Employee No.",
                "Full Name",
                "From",
                "To",
                "Remaining Days",
                "Status",
                "",
                "",
              ]}
              url="/hrm/leave"
              refetchEvents={["leave-updated"]}
              renderRow={renderRow}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
}
function getDropDownItems(item) {
  if (item.status !== "pending") {
    return;
  }
  return [
    {
      value: "Approve",
      icon: "fe fe-check-circle",
      onClick: function () {
        put(`/hrm/leave/${item._id}/approve`, {});
      },
    },
    { isDivider: true },
    {
      value: "Decline",
      icon: "fe fe-x-circle",
      onClick: function () {
        put(`/hrm/leave/${item._id}/decline`, {});
      },
    },
  ];
}

export default Leave;
