import React from "react";
import { Card, Table, Button, Page } from "tabler-react";
import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
import formatMoney from "../../MoneyFormatter";
import { isAdmin } from "../../authHelper";
import { showModal, showConfirmation } from "../../Platform/events";
import { softDelete } from "../../httpUtils";

function SalesReturnsList() {
  // const [modalShow, setModalShow] = React.useState(false);
  const { loading, results: returnItems } = useDataFetchWithPusher(
    "/inventory/sales/returns",
    ["sales-return-created"]
  );

  return (
    <Page.Content title="Sales Returns" className="page-content">
      <Card>
        <Card.Header>
          <Card.Title>Sales Return List</Card.Title>
          <Card.Options>
            <Button
              color="primary"
              size="md"
              onClick={() => showModal("RecordSalesReturnsForm", null)}
            >
              <i className="fe fe-plus-circle mr-2"></i>Record Sales Return
            </Button>
          </Card.Options>
        </Card.Header>

        <Table
          responsive
          className="card-table table-vcenter text-nowrap"
          headerItems={[
            { content: "Item" },
            { content: "Quantity" },
            { content: "Unit Cost" },
            { content: "Total Amount" },
            { content: "Customer" },
            { content: "Receipt No." },
            {},
          ]}
        >
          <Table.Body>
            {loading && !returnItems.length && (
              <Table.Row>
                <Table.Col colSpan="6">Loading...</Table.Col>
              </Table.Row>
            )}
            {!loading && !returnItems.length && (
              <Table.Row>
                <Table.Col colSpan="6">No results found</Table.Col>
              </Table.Row>
            )}
            {returnItems.map((i) => (
              <Table.Row>
                <Table.Col>{i.itemName}</Table.Col>
                <Table.Col>{i.quantity} pieces</Table.Col>
                <Table.Col>{formatMoney(i.unitPrice)}</Table.Col>
                <Table.Col>{formatMoney(i.unitPrice * i.quantity)}</Table.Col>
                <Table.Col>{i.customerName}</Table.Col>
                <Table.Col>{i.receiptNumber}</Table.Col>
                <Table.Col>
                  {isAdmin() && (
                    <Button
                      color="danger"
                      icon="fe fe-trash-2"
                      className="ml-2 pull-right"
                      size="sm"
                      onClick={() => {
                        showConfirmation({
                          text: `Are you sure you want to delete?`,
                          onConfirm() {
                            softDelete(`/inventory/purchases/damages/${i._id}`);
                          },
                        });
                      }}
                    ></Button>
                  )}{" "}
                  <Button
                    className="ml-2 pull-right"
                    color="secondary"
                    icon="fe fe-edit"
                    size="sm"
                    onClick={() => showModal("RecordDamagesForm", { item: i })}
                  >
                    Edit
                  </Button>
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </Page.Content>
  );
}

export default SalesReturnsList;
