import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { subDays, format } from "date-fns";
import ReactToPrint from "react-to-print";
import { Button, Table, Avatar } from "tabler-react";
import { DateRangePicker } from "react-date-range";
import formatMoney from "../../../MoneyFormatter";
import useDataFetchWithPusher from "../../../effects/useDataFetchWithPusher";
import { getUser } from "../../../authHelper";
const Receipt = (props) => {
  const { cooperative } = getUser();
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const { loading, results: sales } = useDataFetchWithPusher(
    `/inventory/sales/total-by-product-category?from=${formatDateForLookUp(
      dateRange[0].startDate
    )}&to=${formatDateForLookUp(dateRange[0].endDate)}`,
    ["sold-item-created"],
    dateRange
  );
  const [showDateRange, setDateRangeVisible] = useState(false);
  const componentRef = useRef();
  return (
    <Modal
      {...props}
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Button
          color="primary"
          icon="calendar"
          onClick={() => {
            setDateRangeVisible(!showDateRange);
          }}
        >
          {showDateRange ? "Hide date range" : "Show date range"}
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" icon="printer">
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          {showDateRange && (
            <DateRangePicker
              onChange={(item) => setDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
            />
          )}
          <div className="row printDiv" ref={componentRef}>
            <div className="well col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <div>
                      <Avatar
                        size="xxl"
                        imageURL={cooperative.logo}
                        alt="Cooperative logo"
                      />
                    </div>
                    <h2>Sales by Product</h2>
                    <strong>{`From ${format(
                      dateRange[0].startDate,
                      "MMM do y"
                    )} to ${format(dateRange[0].endDate, "MMM do y")}`}</strong>
                  </div>
                  <br />
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap"
                    headerItems={[
                      { content: "Product" },
                      { content: "Quantity Sold" },
                      { content: "Amount" },
                    ]}
                  >
                    <Table.Body>
                      {loading && (
                        <Table.Row>
                          <Table.Col colSpan="3">Loading...</Table.Col>
                        </Table.Row>
                      )}
                      {!loading && sales.items && !sales.items.length && (
                        <Table.Row>
                          <Table.Col colSpan="3">No results found</Table.Col>
                        </Table.Row>
                      )}
                      {sales.items &&
                        sales.items.map((i) => (
                          <Table.Row>
                            <Table.Col>{i.name}</Table.Col>
                            <Table.Col>{i.count}</Table.Col>
                            <Table.Col>{formatMoney(i.amount)}</Table.Col>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" icon="x-circle" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
function formatDateForLookUp(date) {
  return format(date, "MM-dd-yyyy");
}
export default Receipt;
