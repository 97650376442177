import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
function useFetchQuestions(additionalEvents = []) {
  const { error, loading, results } = useDataFetchWithPusher(
    "/hrm/performance/questions?limit=10000&offset=0",
    [...additionalEvents, "question-created"]
  );

  return {
    error,
    loading,
    results: results.items || [],
  };
}
export default useFetchQuestions;
