import React from "react";
import { Modal, Button } from "react-bootstrap";
// import { registerGlobalModal } from "./ModalManager";

function ConfirmationModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Confirmation</Modal.Title>

        <Button onClick={props.onHide} variant="outline-danger" size="sm">
          X
        </Button>
      </Modal.Header>
      <Modal.Body>{props.text}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => {
            props.onHide();
          }}
        >
          <i className="fe fe-x mr-2"></i>
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => {
            props.onConfirm();
            props.onHide();
          }}
          variant="outline-success"
        >
          <i className="fe fe-check mr-2"></i>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
