import React from "react";
import { Grid, Card, Table, Button, Page, Form } from "tabler-react";
import fetchStoreItems from "./fetchProductCategories";
import fetchStore from "./fetchStores";
import CurrentStock from "./CurrentStockDisplay";
import StockStatusBadge from "./StockStatusBadge";
import Pagination from "../../Utils/Pagination";
import { softDelete } from "../../httpUtils";
import { isAdmin } from "../../authHelper";
import { showModal, showConfirmation } from "../../Platform/events";
import AmountDisplay from "../../Platform/AmountDisplay";

function StoreList(props) {
  const { results: stores } = fetchStore();
  const [offset, setOffset] = React.useState(0);
  const { loading, results: storeItems } = fetchStoreItems(
    ["store-item-updated"],
    offset
  );
  const [activeStore, setActiveStore] = React.useState(null);

  return (
    <Page.Content className="page-content" title="Products">
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>Items in store</Card.Title>

              <Card.Options>
                <div>
                  <Form.Select
                    name="store"
                    onChange={(e) => {
                      const value =
                        e.target.value === "none" ? null : e.target.value;
                      setActiveStore(value);
                    }}
                  >
                    <option value="none">Select store</option>
                    {stores.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </Form.Select>
                </div>
                <div style={{ paddingTop: 0, paddingLeft: 7 }}>
                  <Button
                    color="primary"
                    size="md"
                    onClick={() => showModal("ProductCategoriesForm", null)}
                  >
                    <i className="fe fe-plus-circle mr-2"></i> Add new product
                  </Button>
                </div>
              </Card.Options>
            </Card.Header>

            <Table
              responsive
              className="card-table table-vcenter text-nowrap"
              headerItems={[
                { content: "Item Name" },
                { content: "Selling Price" },
                { content: "Purchase Cost" },
                {
                  content:
                    !activeStore || activeStore === "none"
                      ? "Quantity in all stores"
                      : "Quantity in store",
                },
                { content: "Re-order Level" },
                { content: "Status" },
                {},
              ]}
            >
              <Table.Body>
                {loading && (
                  <Table.Row>
                    <Table.Col colSpan="6">Loading...</Table.Col>
                  </Table.Row>
                )}
                {!loading && storeItems.items && !storeItems.items.length && (
                  <Table.Row>
                    <Table.Col colSpan="6">No results found</Table.Col>
                  </Table.Row>
                )}
                {storeItems.items &&
                  storeItems.items.map((i) => {
                    return (
                      <Table.Row>
                        <Table.Col>{i.name}</Table.Col>
                        <Table.Col>
                          <AmountDisplay amount={i.price} />
                        </Table.Col>
                        <Table.Col>
                          <AmountDisplay amount={i.cost} />
                        </Table.Col>
                        <Table.Col>
                          <CurrentStock product={i} store={activeStore} />
                        </Table.Col>
                        <Table.Col>
                          {i.minimum} {i.unit}
                        </Table.Col>
                        <Table.Col>
                          <StockStatusBadge product={i} store={activeStore} />
                        </Table.Col>
                        <Table.Col>
                          {isAdmin() && (
                            <Button
                              className="ml-2 pull-right"
                              color="danger"
                              icon="fe fe-trash-2"
                              size="sm"
                              onClick={() => {
                                showConfirmation({
                                  text: `Are you sure you want to delete ${i.name}?`,
                                  onConfirm() {
                                    softDelete(
                                      `/inventory/store/items/${i._id}`
                                    );
                                  },
                                });
                              }}
                            ></Button>
                          )}{" "}
                          <Button
                            className="ml-2 pull-right"
                            color="secondary"
                            icon="fe fe-edit"
                            size="sm"
                            onClick={() =>
                              showModal("ProductCategoriesForm", { item: i })
                            }
                          >
                            Edit
                          </Button>
                        </Table.Col>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
            <Pagination
              onPageChange={(offsetR) => {
                setOffset(offsetR);
              }}
              totalItemsCount={storeItems.count || 0}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
}

export default StoreList;
