import eventEmitter from "./eventEmitter";
function showModal(modalType, props) {
  eventEmitter.emit("showModal", { modalType, props });
}
function showConfirmation({ text, onConfirm }) {
  showModal("ConfirmationModal", {
    text,
    onConfirm,
  });
}
export { showModal, showConfirmation };
