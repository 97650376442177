import * as React from "react";
import { Formik } from "formik";
import { LoginPage as TablerLoginPage } from "tabler-react";
import { post } from "./httpUtils";
import { saveUser, logout } from "./authHelper";
class Login extends React.Component {
  state = {
    redirect: false,
  };
  componentWillMount() {
    logout();
  }
  render() {
    if (this.state.redirect) {
      window.location.replace("/home");
    }
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={(values) => {
          // same as above, but feel free to move this into a class method now.
          let errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={async (
          values,
          { setSubmitting, setErrors /* setValues and other goodies */ }
        ) => {
          const { email, password } = values;
          try {
            const res = await post("/auth/login", {
              username: email,
              password,
            });
            console.log(res);
            saveUser(res);
            this.setState({ redirect: true });
          } catch (e) {
            let errors = {};
            errors.email = "Invalid email details";
            errors.password = "Invalid password details";
            setErrors(errors);
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <TablerLoginPage
            onSubmit={handleSubmit}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
        )}
      />
    );
  }
}

export default Login;
