import React from "react";
import { Badge } from "tabler-react";
const possibleLicenses = ["INV", "CRM", "HRM", "KYC"];
const LicenseBadges = ({ licenses }) => {
  console.log("Licenses", licenses);
  return (
    <React.Fragment>
      {possibleLicenses.map((license) => (
        <Badge
          key={license}
          color={licenses.includes(license) ? "success" : "secondary"}
          className="mr-1 badges "
        >
          {license}
        </Badge>
      ))}
    </React.Fragment>
  );
};

export default LicenseBadges;
