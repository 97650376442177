import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { Alert, Form, Grid } from "tabler-react";
import useForm from "../../effects/useForm";
import { put } from "../../httpUtils";
import fetchProductCategories from "./fetchProductCategories";
import fetchStores from "./fetchStores";

const TransferStockForm = (props) => {
  const { handleChange, handleSubmit, values } = useForm(submit);
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { results: stores } = fetchStores();
  const { results: products } = fetchProductCategories();
  async function submit(data) {
    const { item } = data;
    try {
      await put(`/inventory/store/transfer-stock/${item}`, data);
      addToast("Goods transfer successful", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Transfer Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}

          <Form.FieldSet>
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Number of items">
                    <Form.Input
                      name="numberOfItems"
                      placeholder="Quantity"
                      onChange={handleChange}
                      type="number"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select label="Item" name="item" onChange={handleChange}>
                    <option value="">Select item</option>
                    {products.items &&
                      products.items.map((product) => (
                        <option value={product._id}>{product.name}</option>
                      ))}
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Select
                    label="From"
                    name="fromStore"
                    onChange={handleChange}
                  >
                    <option value="">Select from store</option>
                    {stores
                      .filter((i) => i._id !== values.toStore)
                      .map((store) => (
                        <option value={store._id}>{store.name}</option>
                      ))}
                  </Form.Select>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select
                    label="To"
                    name="toStore"
                    onChange={handleChange}
                  >
                    <option value="">Select from store</option>
                    {stores
                      .filter((i) => i._id !== values.fromStore)
                      .map((store) => (
                        <option value={store._id}>{store.name}</option>
                      ))}
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => {
              props.onHide();
            }}
          >
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            Transfer
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TransferStockForm;
