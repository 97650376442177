import React from "react";
import { Grid, Card, Button, Table, Page } from "tabler-react";
import { Link } from "react-router-dom";
import { softDelete } from "../../httpUtils";
import { showConfirmation, showModal } from "../../Platform/events";
import { registerGlobalModal } from "../../Platform/ModalManager";
import PaginatedTable from "../../Platform/PaginatedTable";
import AddQuestion from "./AddQuestion";

registerGlobalModal("AddQuestion", AddQuestion);
const Questions = () => {
  let renderRow = (i) => {
    return (
      <>
        <Table.Col>{i.question}</Table.Col>
        <Table.Col>{i.target}</Table.Col>
        <Table.Col>{i.type}</Table.Col>

        <Table.Col>
          <Button
            icon="fe fe-edit"
            color="secondary"
            size="sm"
            className="ml-2 pull-right"
            onClick={() => {
              showModal("AddQuestion", { question: i });
            }}
          >
            Edit
          </Button>
          <Button
            icon="fe fe-trash-2"
            color="danger"
            size="sm"
            className="ml-2 pull-right"
            onClick={() => {
              showConfirmation({
                text: `Are you sure you want to delete?`,
                onConfirm() {
                  softDelete(`/hrm/performance/questions/${i._id}`);
                },
              });
            }}
          >
            Delete
          </Button>
        </Table.Col>
      </>
    );
  };
  return (
    <Page.Content className="page-content" title="Manage Questions">
      <Grid>
        <Grid.Row>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Questions</Card.Title>
                <Card.Options>
                  <Button
                    color="primary"
                    size="md"
                    onClick={() => showModal("AddQuestion")}
                  >
                    <i className="fe fe-plus-circle mr-2"></i> Add Question
                  </Button>
                  <Link to="/hrm/performance">
                    <Button color="success" size="md">
                      Return to performance
                    </Button>
                  </Link>
                </Card.Options>
              </Card.Header>

              <PaginatedTable
                columns={["Question", "Target score", "Type", ""]}
                url="/hrm/performance/questions"
                refetchEvents={["questions-updated", "questions-created"]}
                renderRow={renderRow}
              ></PaginatedTable>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Page.Content>
  );
};

export default Questions;
