import React from "react";
import { Grid, Card, Table, Button, Page } from "tabler-react";
import License from "./License";
import ListOfUsers from "./UsersPerCooperative";
import { softDelete } from "../httpUtils";
import LicenseBadges from "./LicenseBadges";
import { showModal, showConfirmation } from "../Platform/events";
import PaginatedTable from "../Platform/PaginatedTable";

function CooperativeList() {
  const [licenseShow, setLicenseShow] = React.useState(false);
  const [usersShow, setUsersShow] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  // const [offset, setOffset] = React.useState(0);

  let renderRow = (i) => {
    return (
      <>
        <Table.Col>{i.name}</Table.Col>
        <Table.Col>{i.address}</Table.Col>
        <Table.Col>
          <LicenseBadges licenses={i.licenses}></LicenseBadges>
        </Table.Col>
        <Table.Col alignContent="right">
          <Button
            color="danger"
            icon="fe fe-trash-2"
            className="ml-2 pull-right"
            size="sm"
            onClick={() => {
              showConfirmation({
                text: `Are you sure you want to delete ${i.name} ?`,
                onConfirm() {
                  softDelete(`/users/cooperatives/${i._id}`);
                },
              });
            }}
          ></Button>{" "}
          <Button
            color="primary"
            icon="fe fe-edit"
            className="ml-2 pull-right"
            size="sm"
            onClick={() => showModal("CreateCooperative", { cooperative: i })}
          ></Button>{" "}
          <Button
            icon="fe fe-users"
            color="secondary"
            size="sm"
            onClick={() => {
              setSelected(i);
              setUsersShow(true);
            }}
          >
            View users
          </Button>{" "}
          <Button
            icon="fe fe-edit"
            color="secondary"
            size="sm"
            onClick={() => {
              setSelected(i);
              setLicenseShow(true);
            }}
          >
            Licenses
          </Button>
        </Table.Col>
      </>
    );
  };
  return (
    <Page.Content title="Cooperatives" className="page-content">
      {usersShow && (
        <ListOfUsers
          show={usersShow}
          cooperative={selected}
          onHide={() => {
            setSelected({});
            setUsersShow(false);
          }}
        />
      )}
      <License
        show={licenseShow}
        cooperative={selected}
        onHide={() => {
          setSelected({});
          setLicenseShow(false);
        }}
      />
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>List of Cooperatives</Card.Title>
              <Card.Options>
                <Button
                  color="primary"
                  size="md"
                  onClick={() =>
                    showModal("CreateCooperative", { cooperative: null })
                  }
                >
                  <i className="fe fe-plus-circle mr-2"></i>Add cooperative
                </Button>
              </Card.Options>
            </Card.Header>
            <PaginatedTable
              columns={["Cooperative", "Address", "Active licenses", ""]}
              url="/users/cooperatives"
              refetchEvents={["cooperative-created", "cooperative-updated"]}
              renderRow={renderRow}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
}

export default CooperativeList;
