const translate = (user) => {
  if (user === "admin") {
    return "Administrator";
  } else if (user === "super-admin") {
    return "Super Administrator";
  }
  return "User";
};

export default translate;
