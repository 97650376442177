import React, { useEffect } from "react";
import { Table } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import Toggle from "react-toggle";
import { put } from "../httpUtils";
import { useState } from "react";

function CreateUserForm(props) {
  const { cooperative } = props;
  const { licenses: licensesP } = cooperative;
  const [licenses, setLicenses] = useState([]);
  useEffect(() => {
    setLicenses((state) => licensesP || []);
  }, [licensesP]);
  function submit() {
    put(`/users/cooperatives/${cooperative.id}`, { licenses });
    props.onHide();
  }
  const possibleLicenses = {
    INV: "Inventory",
    CRM: "Content Relation Management",
    HRM: "Human Resource Management",
    KYC: "Know Your Customer",
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onExit={() => props.onHide()}
      centered
    >
      <Modal.Header>
        <Modal.Title>{cooperative.name}</Modal.Title>
      </Modal.Header>

      <Table
        responsive
        className="card-table table-vcenter text-nowrap"
        headerItems={[{ content: "Licenses" }, {}]}
      >
        <Table.Body>
          {Object.keys(possibleLicenses).map((i) => (
            <Table.Row key={i}>
              <Table.Col>{possibleLicenses[i]}</Table.Col>
              <Table.Col alignContent="right">
                <Toggle
                  onChange={() => {
                    if (licenses.includes(i)) {
                      setLicenses(licenses.filter((item) => item !== i));
                    } else {
                      setLicenses(licenses.concat(i));
                    }
                  }}
                  checked={licenses.includes(i)}
                  icons={false}
                />
              </Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal.Footer>
        <Button
          type="submit"
          variant="outline-success"
          onClick={() => submit()}
        >
          <i className="fe fe-check mr-2"></i>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateUserForm;
