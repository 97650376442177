import { format } from "date-fns";
import moment from "moment";
function formatDateForLookUp(date) {
  return format(date, "MM-dd-yyyy");
}
function formatDateForDisplay(date) {
  return moment(date).format("MMM Do YYYY");
}
function dateDifference(fromDate, toDate) {
  return moment(toDate).diff(moment(fromDate), "days");
}
function dateDifferenceFromToday(toDate) {
  const result = parseInt(moment(toDate).diff(moment(), "days"));
  if (result < 0) {
    return 0;
  }
  if (result > 0) {
    return result + 1;
  }
  return result;
}

export {
  formatDateForLookUp,
  dateDifference,
  formatDateForDisplay,
  dateDifferenceFromToday,
};
