import React from "react";
import { Link } from "react-router-dom";
import HomeCardContent from "./HomeCardContent";

const ModuleEnabled = ({ to, ...props }) => {
  return (
    <Link to={to}>
      <HomeCardContent {...props} />
    </Link>
  );
};

export default ModuleEnabled;
