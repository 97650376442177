import React, { useState } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
import { post } from "../../httpUtils";
import fetchEmployees from "../Employees/fetchEmployees";
import fetchQuestions from "./fetchQuestions";
// import { getUser } from "../../authHelper";
// import getUserType from "../../Utils/AccountTranslator";
import { useToasts } from "react-toast-notifications";
import Select from "react-dropdown-select";

const PerformanceForm = (props) => {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleSubmit, addToObject, manualChange } = useForm(submit);
  const { results: employees } = fetchEmployees();
  const { results: questions } = fetchQuestions();
  async function submit(data) {
    //validate
    try {
      await post("/hrm/performance", data);
      addToast("Assessment Submitted Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Performance assessment</Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet className="Performance assessment">
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Employee">
                    <Select
                      name="employee"
                      placeholder="Select employee"
                      searchBy="employee"
                      valueField="_id"
                      labelField="fullname"
                      options={employees}
                      onChange={(values) => {
                        if (values.length) {
                          manualChange({
                            name: "employee",
                            value: values[0]._id,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label="Performed By">
                    <Select
                      name="performedBy"
                      placeholder="Select employee"
                      searchBy="employee"
                      valueField="_id"
                      labelField="fullname"
                      options={employees}
                      onChange={(values) => {
                        if (values.length) {
                          manualChange({
                            name: "performedBy",
                            value: values[0]._id,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={12}>
                  {questions.map((question) =>
                    question.type === "score" ? (
                      <Form.Group label={question.question}>
                        <Form.Input
                          name="question[]"
                          type="number"
                          onChange={(e) =>
                            addToObject("answers", {
                              [question._id]: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group label={question.question}>
                        <Form.Textarea
                          name="question[]"
                          onChange={(e) =>
                            addToObject("answers", {
                              [question._id]: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    )
                  )}
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            Submit assessment
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PerformanceForm;
