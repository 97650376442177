import React from "react";
import { Table } from "tabler-react";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
import Pagination from "../Utils/Pagination";

const PaginatedTable = ({
  url,
  refetchEvents,
  columns,
  renderRow,
  rowStyle,
}) => {
  const [offset, setOffset] = React.useState(0);
  const { loading, results } = useDataFetchWithPusher(
    `${url}?offset=${offset}&&limit=10`,
    refetchEvents
  );
  let headerItems = columns.map((i) => ({
    content: i,
  }));
  return (
    <>
      <Table
        responsive
        className="card-table table-vcenter text-nowrap"
        headerItems={headerItems}
      >
        <Table.Body>
          {loading && (!results.items || !results.items.length) && (
            <Table.Row>
              <Table.Col colSpan={headerItems.length}>Loading...</Table.Col>
            </Table.Row>
          )}
          {!loading && (!results.items || !results.items.length) && (
            <Table.Row>
              <Table.Col colSpan={headerItems.length}>
                No results found
              </Table.Col>
            </Table.Row>
          )}
          {results.items &&
            results.items.map((i) => (
              <Table.Row key={i._id} style={rowStyle ? rowStyle(i) : {}}>
                {renderRow(i)}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Pagination
        onPageChange={(offsetR) => {
          setOffset(offsetR);
        }}
        totalItemsCount={results.count || 0}
      />
    </>
  );
};

export default PaginatedTable;
