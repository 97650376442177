import React, { useRef } from "react";
import { Grid, Card, Table, Button, Page, Avatar } from "tabler-react";
import ReactToPrint from "react-to-print";
// import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
import PayrollForm from "./PayrollForm";
import PaymentReport from "./PaymentReport";
// import formatMoney from "../../MoneyFormatter";
import { formatDateForDisplay } from "../../DateUtils";
import { softDelete } from "../../httpUtils";
import { showConfirmation, showModal } from "../../Platform/events";
import { registerGlobalModal } from "../../Platform/ModalManager";
import { getUser, isAdmin } from "../../authHelper";
import PaginatedTable from "../../Platform/PaginatedTable";
import AmountDisplay from "../../Platform/AmountDisplay";

registerGlobalModal("PayrollForm", PayrollForm);
registerGlobalModal("PaymentReport", PaymentReport);

function Payroll(props) {
  const componentRef = useRef();
  const { cooperative } = getUser();
  let renderRow = (i) => {
    return (
      <React.Fragment>
        <Table.Col>{i.employee.employeeNo}</Table.Col>
        <Table.Col>{i.employee.fullname}</Table.Col>
        <Table.Col>
          <AmountDisplay amount={i.gross} />
        </Table.Col>
        <Table.Col>
          <AmountDisplay amount={i.tax} />
        </Table.Col>
        <Table.Col>
          <AmountDisplay amount={i.nssf} />
        </Table.Col>
        <Table.Col>
          <AmountDisplay amount={i.deductions} />
        </Table.Col>
        <Table.Col>
          <AmountDisplay
            amount={i.gross - (i.advance + i.deductions + i.nssf + i.tax)}
          />
        </Table.Col>
        <Table.Col>{formatDateForDisplay(i.created)}</Table.Col>
        <Table.Col>
          <Button
            icon="fe fe-eye"
            color="secondary"
            className="ml-2 pull-right hidden-on-print"
            size="sm"
            onClick={() => {
              showModal("PaymentReport", { selected: i });
            }}
          >
            history
          </Button>
        </Table.Col>
        <Table.Col>
          {isAdmin() && (
            <Button
              color="danger"
              icon="fe fe-trash-2"
              className="ml-2 pull-right hidden-on-print"
              size="sm"
              onClick={() => {
                showConfirmation({
                  text: "Are you sure you want to delete payment?",
                  onConfirm() {
                    softDelete(`/hrm/payroll/${i._id}`);
                  },
                });
              }}
            ></Button>
          )}{" "}
        </Table.Col>
      </React.Fragment>
    );
  };

  return (
    <Page.Content className="page-content" title="Payroll">
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>Payroll sheet</Card.Title>
              <Card.Options>
                <Button
                  color="primary"
                  size="md"
                  onClick={() => showModal("PayrollForm", null)}
                >
                  <i className="fe fe-plus-circle mr-2"></i> Record salary
                </Button>
                {""}
                <ReactToPrint
                  trigger={() => (
                    <Button color="success" icon="printer">
                      Print payroll
                    </Button>
                  )}
                  content={() => componentRef.current}
                />
              </Card.Options>
            </Card.Header>
            <div className="printDiv" ref={componentRef}>
              <div className="col-md-12 show-only-on-print">
                <div className="text-center">
                  <div>
                    <Avatar
                      size="xxl"
                      imageURL={cooperative.logo}
                      alt="Cooperative logo"
                    />
                  </div>
                  <h2>Payroll</h2>
                </div>
              </div>
              <PaginatedTable
                columns={[
                  "Employee No.",
                  "Full Name",
                  "Gross",
                  "Tax",
                  "NSSF",
                  "Deductions",
                  "Net pay",
                  "Date",
                  "",
                  "",
                ]}
                url="/hrm/payroll"
                refetchEvents={["payroll-created", "payroll-updated"]}
                renderRow={renderRow}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
}

export default Payroll;
