import React from "react";
import { Icon } from "tabler-react";
const HomeCardContent = ({ module, icon }) => {
  return (
    <div style={{ alignSelf: "center", textAlign: "center", padding: 10 }}>
      <Icon prefix="fa" name={icon} className="home-icon" />
      <span style={{ display: "block", paddingTop: 10 }}>{module}</span>
    </div>
  );
};

export default HomeCardContent;
