import useDataFetchWithPusher from "../../../effects/useDataFetchWithPusher";
function useFetchStoreItems() {
  const { error, loading, results } = useDataFetchWithPusher(
    "/inventory/purchases/suppliers",
    ["supplier-created"]
  );

  return {
    error,
    loading,
    results
  };
}
export default useFetchStoreItems;
