import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
function useFetchCategory(additionalEvents = []) {
  const {
    error,
    loading,
    results
  } = useDataFetchWithPusher("/inventory/store/category", [
    ...additionalEvents,
    "category-created"
  ]);

  return {
    error,
    loading,
    results
  };
}
export default useFetchCategory;
