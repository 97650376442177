import React from "react";
import { showModal } from "./events";
import { Form } from "tabler-react";
import { Button } from "react-bootstrap";
const SelectWithAdd = ({
  label,
  name,
  onChange,
  defaultText,
  modalType,
  selected,
  options,
}) => {
  return (
    <Form.InputGroup>
      <Form.Select label={label} name={name} onChange={onChange}>
        <option>{defaultText}</option>
        {options &&
          options.map((item) => (
            <option
              selected={selected && selected === item.value}
              value={item.value}
            >
              {item.display}
            </option>
          ))}
      </Form.Select>
      <Form.InputGroupAppend>
        <Button color="primary" onClick={() => showModal(modalType, null)}>
          +
        </Button>
      </Form.InputGroupAppend>
    </Form.InputGroup>
  );
};

export default SelectWithAdd;
