import React from "react";
import { Grid, StampCard } from "tabler-react";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
function KycSummary() {
  const { loading, results } = useDataFetchWithPusher("/kyc", [
    "customer-created"
  ]);
  const {
    loadingTransaction,
    results: transactions
  } = useDataFetchWithPusher("/kyc/transactions", ["transaction-created"]);

  return (
    <React.Fragment>
      <Grid.Col sm={6} lg={3}>
        <StampCard
          color="green"
          icon="fe fe-users"
          header={
            <span>
              {!loading && `${results.length} `}
              <small>Customers</small>
            </span>
          }
          footer={""}
        />
      </Grid.Col>
      <Grid.Col sm={6} lg={3}>
        <StampCard
          color="blue"
          icon="fe fe-file-text"
          header={
            <span>
              {!loadingTransaction && `${transactions.length} `}
              <small>Transactions</small>
            </span>
          }
          footer={""}
        />
      </Grid.Col>
      <Grid.Col sm={6} lg={3}>
        <StampCard
          color="orange"
          icon="fe fe-alert-octagon"
          header={
            <span>
              {!loadingTransaction && `${transactions.length} `}
              <small>Complaints</small>
            </span>
          }
          footer={""}
        />
      </Grid.Col>
      <Grid.Col sm={6} lg={3}>
        <StampCard
          color="red"
          icon="fe fe-alert-triangle"
          header={
            <span>
              {!loadingTransaction && `${transactions.length} `}
              <small>Alerts</small>
            </span>
          }
          footer={""}
        />
      </Grid.Col>
    </React.Fragment>
  );
}

export default KycSummary;
