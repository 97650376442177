import React, { useState } from "react";
import { Table, Button } from "tabler-react";
import TrainingForm from "./TrainingForm";
import moment from "moment";
const Training = ({ profile }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <React.Fragment>
      <TrainingForm
        id={profile._id}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <Button color="primary" size="sm" onClick={() => setShowModal(true)}>
          Add new Training
        </Button>
      </div>
      <Table
        responsive
        className="card-table table-vcenter text-nowrap"
        headerItems={[
          { content: "Name" },
          { content: "Institution" },
          { content: "From" },
          { content: "To" },
          {}
        ]}
      >
        <Table.Body>
          {(!profile.trainings || !profile.trainings.length) && (
            <Table.Row>
              <Table.Col>No Training Data added</Table.Col>
            </Table.Row>
          )}
          {profile.trainings &&
            profile.trainings.length &&
            profile.trainings.map(i => (
              <Table.Row>
                <Table.Col>{i.name}</Table.Col>
                <Table.Col>{i.institution}</Table.Col>
                <Table.Col>{moment(i.from).format("MMM Do YYYY")}</Table.Col>
                <Table.Col>{moment(i.to).format("MMM Do YYYY")}</Table.Col>
                <Table.Col>
                  <Button color="danger" size="sm" className="ml-2">
                    Delete
                  </Button>
                </Table.Col>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default Training;
