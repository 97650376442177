import React from "react";
import { Grid, Card, Table, Button, Page } from "tabler-react";
// import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
import AttendanceForm from "./AttendanceForm";
import AttendanceReport from "./AttendanceReport";
import moment from "moment";
import { showModal } from "../../Platform/events";
import { registerGlobalModal } from "../../Platform/ModalManager";
import PaginatedTable from "../../Platform/PaginatedTable";

registerGlobalModal("AttendanceForm", AttendanceForm);
registerGlobalModal("AttendanceReport", AttendanceReport);
function Attendance(props) {
  let renderRow = (i) => {
    return (
      <React.Fragment>
        <Table.Col>{i.employee.fullname}</Table.Col>
        <Table.Col>{i.timeIn}</Table.Col>
        <Table.Col>{i.timeOut}</Table.Col>
        <Table.Col>{moment(i.date).format("MMM Do YYYY")}</Table.Col>
        <Table.Col>{i.status}</Table.Col>
        <Table.Col>
          <Button
            icon="fe fe-eye"
            color="secondary"
            size="sm"
            className="ml-2 pull-right"
            onClick={() => showModal("AttendanceReport", { selected: i })}
          >
            View History
          </Button>
        </Table.Col>
      </React.Fragment>
    );
  };
  return (
    <Page.Content className="page-content" title="Attendance">
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>Attendance sheet</Card.Title>
              <Card.Options>
                <Button
                  color="primary"
                  size="md"
                  onClick={() => showModal("AttendanceForm", null)}
                >
                  <i className="fe fe-plus-circle mr-2"></i> Register attendance
                </Button>
              </Card.Options>
            </Card.Header>
            <PaginatedTable
              columns={["Name", "Time In", "Time Out", "Date", "Status", ""]}
              url="/hrm/attendance"
              refetchEvents={["attendance-created", "attendance-updated"]}
              renderRow={renderRow}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
}

export default Attendance;
