import React from "react";
import { Card, Table, Button, Page } from "tabler-react";
import fetchStores from "./fetchStores";
import { softDelete } from "../../httpUtils";
import { showConfirmation, showModal } from "../../Platform/events";
import { isAdmin } from "../../authHelper";

function StoreList() {
  const { loading, results } = fetchStores();

  return (
    <Page.Content className="page-content" title="Stores">
      <Card>
        <Card.Header>
          <Card.Title>Store List</Card.Title>
          <Card.Options>
            <Button
              color="primary"
              size="md"
              onClick={() => showModal("StoreForm", null)}
            >
              <i className="fe fe-plus-circle mr-2"></i>Add new Store
            </Button>
          </Card.Options>
        </Card.Header>

        <Table
          responsive
          className="card-table table-vcenter text-nowrap"
          headerItems={[{ content: "Store Name" }, { content: "Location" }, {}]}
        >
          {loading && !results.length && (
            <Table.Row>
              <Table.Col colSpan="5">Loading...</Table.Col>
            </Table.Row>
          )}
          {!loading && !results.length && (
            <Table.Row>
              <Table.Col colSpan="5">No results found</Table.Col>
            </Table.Row>
          )}
          {results.map((i) => (
            <Table.Row>
              <Table.Col>{i.name}</Table.Col>
              <Table.Col>{i.location}</Table.Col>
              <Table.Col>
                {isAdmin() && (
                  <Button
                    color="danger"
                    icon="fe fe-trash-2"
                    className="ml-2 pull-right"
                    size="sm"
                    onClick={() => {
                      showConfirmation({
                        text: `Are you sure you want to delete ${i.name}?`,
                        onConfirm() {
                          softDelete(`/inventory/store/${i._id}`);
                        },
                      });
                    }}
                  ></Button>
                )}
                <Button
                  className="ml-2 pull-right"
                  color="secondary"
                  icon="fe fe-edit"
                  size="sm"
                  onClick={() => showModal("StoreForm", { item: i })}
                >
                  Edit
                </Button>
              </Table.Col>
            </Table.Row>
          ))}
        </Table>
      </Card>
    </Page.Content>
  );
}

export default StoreList;
