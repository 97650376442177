import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Form, Grid, Alert } from "tabler-react";
import useForm from "../../effects/useForm";
import { post } from "../../httpUtils";
const TrainingForm = props => {
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit } = useForm(submit);
  async function submit(data) {
    try {
      await post(`/hrm/${props.id}/education`, data);
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            <h3 className="card-title">Add Education</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.map(error => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Grid>
            <Grid.Row>
              <Grid.Col width={6}>
                <Form.Input
                  type="text"
                  label="Qualification"
                  name="name"
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col width={6}>
                <Form.Input
                  type="text"
                  label="Institution"
                  name="institution"
                  onChange={handleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={6}>
                <Form.Input
                  type="date"
                  label="From"
                  name="from"
                  onChange={handleChange}
                />
              </Grid.Col>
              <Grid.Col width={6}>
                <Form.Input
                  type="date"
                  label="To"
                  name="to"
                  onChange={handleChange}
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={12}>
                <Form.Group label="Document">
                  <Form.FileInput label="Document" name="from" />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col width={12}>
                <Form.Textarea
                  label="Description"
                  name="description"
                  onChange={handleChange}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            Add Education
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TrainingForm;
