import React, { useState } from "react";
import { Table, Button } from "tabler-react";
import moment from "moment";
import EducationForm from "./EducationForm";
const Training = ({ profile }) => {
  const [showModal, setShowModal] = useState(false);
  const { education } = profile;
  return (
    <React.Fragment>
      <EducationForm
        id={profile._id}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <Button color="primary" size="sm" onClick={() => setShowModal(true)}>
          Add Eduction
        </Button>
      </div>

      <Table
        responsive
        className="card-table table-vcenter text-nowrap"
        headerItems={[
          { content: "Name" },
          { content: "Institution" },
          { content: "From" },
          { content: "To" },
          {}
        ]}
      >
        <Table.Body>
          {!education || !education.length ? (
            <Table.Row>
              <Table.Col>No Eduction Data added</Table.Col>
            </Table.Row>
          ) : (
            education.map(i => {
              return (
                <Table.Row>
                  <Table.Col>{i.name}</Table.Col>
                  <Table.Col>{i.institution}</Table.Col>
                  <Table.Col>{moment(i.from).format("MMM Do YYYY")}</Table.Col>
                  <Table.Col>{moment(i.to).format("MMM Do YYYY")}</Table.Col>
                  <Table.Col>
                    <Button
                      color="danger"
                      size="sm"
                      className="ml-2 pull-right"
                    >
                      Delete
                    </Button>
                  </Table.Col>
                </Table.Row>
              );
            })
          )}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default Training;
