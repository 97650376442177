import React, { useState, useEffect } from "react";
import { Form, Grid, Alert, Avatar } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
// import { post, put } from "../../httpUtils";
// import useNumberFetch from "../../effects/useNumberFetch";
import { useToasts } from "react-toast-notifications";
// import { softDelete } from "../../httpUtils";
// import { showConfirmation, showModal } from "../../Platform/events";
import fetchEmployees from "../Employees/fetchEmployees";
import { put } from "../../httpUtils";

const Terminate = (props) => {
  const { results: employees } = fetchEmployees();
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit, setInitialValues } = useForm(submit);
  const { item } = props;
  // const isEdit = typeof item !== "undefined" && item !== null && Object.keys(item).length;
  useEffect(() => {
    if (
      typeof item !== "undefined" &&
      item !== null &&
      Object.keys(item).length
    ) {
      setInitialValues(item);
    }
    //eslint-disable-next-line
  }, [item]);
  // const { results: nextNumber } = useNumberFetch("EM");

  async function submit(data) {
    //validate
    try {
      await put(`/hrm/employees/${item._id}/terminate`, data);
      addToast("Terminated Employee Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!item || item.fullname === undefined
              ? "Terminate employee"
              : `Terminate ${item.fullname}`}
          </Modal.Title>{" "}
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet className="NewItem">
            <Grid>
              <Grid.Row>
                <Grid.Col width={2}>
                  <div style={{ paddingTop: 20 }}>
                    <Avatar size="xxl" imageURL={item.avatar} />
                  </div>
                </Grid.Col>
                <Grid.Col>
                  <div style={{ paddingRight: 10 }}>
                    <table className="table card-table table-vcenter text-nowrap">
                      <tr>
                        <th>Name</th>
                        <td>{item.fullname}</td>
                      </tr>
                      <tr>
                        <th>Employee No</th>
                        <td>{item.employeeNo}</td>
                      </tr>
                      <tr>
                        <th>Department</th>
                        <td style={{ textTransform: "uppercase" }}>
                          {item.department.name}
                        </td>
                      </tr>
                    </table>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Grid>
            <hr />
            <Grid>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Date of Termination">
                    <Form.Input
                      name="date"
                      type="date"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Select
                    label="Authorized By"
                    name="authorizedBy"
                    onChange={handleChange}
                  >
                    <option>Select employee</option>
                    {employees.map((item) => (
                      <option value={item._id}>{item.fullname}</option>
                    ))}
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Reason for Termination">
                    <Form.Textarea
                      name="reason"
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!item || !item.fullname
              ? `Terminate employee`
              : `Terminate ${item.fullname}`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Terminate;
