import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
function useFetchStoreItems(additionalEvents = []) {
  const { error, loading, results } = useDataFetchWithPusher(
    "/inventory/store",
    [...additionalEvents, "store-created"]
  );

  return {
    error,
    loading,
    results
  };
}
export default useFetchStoreItems;
