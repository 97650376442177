import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { subDays, format } from "date-fns";
import { Button, Badge, Table } from "tabler-react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { DateRangePicker } from "react-date-range";
import formatMoney from "../../MoneyFormatter";
import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";

const Receipt = (props) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const { loading, results: sales } = useDataFetchWithPusher(
    `/inventory/sales/by-customer/${
      props.customer && props.customer._id
    }?offset=0&limit=10000&from=${formatDateForLookUp(
      dateRange[0].startDate
    )}&to=${formatDateForLookUp(dateRange[0].endDate)}`,
    ["sold-item-created"],
    dateRange
  );
  const [showDateRange, setDateRangeVisible] = useState(false);
  const componentRef = useRef();
  if (!props.customer) return <div />;
  return (
    <Modal
      {...props}
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Button
          color="primary"
          icon="calendar"
          onClick={() => {
            setDateRangeVisible(!showDateRange);
          }}
        >
          {showDateRange ? "Hide date range" : "Show date range"}
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" icon="printer">
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          {showDateRange && (
            <DateRangePicker
              onChange={(item) => setDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
            />
          )}
          <div className="row printDiv" ref={componentRef}>
            <div className="well col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <h2>Customer Transaction History</h2>
                    <h3>{props.customer.name}</h3>
                    <strong>{`From ${format(
                      dateRange[0].startDate,
                      "MMM do y"
                    )} to ${format(dateRange[0].endDate, "MMM do y")}`}</strong>
                  </div>
                  <br />
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap"
                    headerItems={[
                      { content: "Date" },
                      { content: "Receipt #" },
                      { content: "Customer name" },
                      { content: "Store" },
                      { content: "Status" },
                      { content: "Total amount" },
                    ]}
                  >
                    <Table.Body>
                      {loading && (
                        <Table.Row>
                          <Table.Col colSpan="6">Loading...</Table.Col>
                        </Table.Row>
                      )}
                      {!loading && sales.items && !sales.items.length && (
                        <Table.Row>
                          <Table.Col colSpan="6">No results found</Table.Col>
                        </Table.Row>
                      )}
                      {sales.items &&
                        sales.items.map((i) => (
                          <Table.Row>
                            <Table.Col>
                              {moment(i.date).format("MMM Do YYYY")}
                            </Table.Col>
                            <Table.Col>{i.receiptNo}</Table.Col>
                            <Table.Col>
                              {i.customer && i.customer.name}
                            </Table.Col>
                            <Table.Col>{i.store.name}</Table.Col>
                            <Table.Col>
                              {i.paid && (
                                <Badge color="success" className="mr-1">
                                  Paid
                                </Badge>
                              )}
                              {!i.paid && (
                                <Badge color="danger" className="mr-1">
                                  Not Paid
                                </Badge>
                              )}
                            </Table.Col>
                            <Table.Col>{formatMoney(i.total)}</Table.Col>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" icon="x-circle" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
function formatDateForLookUp(date) {
  return format(date, "MM-dd-yyyy");
}
export default Receipt;
