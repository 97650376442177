import * as React from "react";
import { Page } from "tabler-react";
import SiteWrapper from "./SiteWrapper";
// import { Link } from "react-router-dom";
import "tabler-react/dist/Tabler.css";
import "font-awesome/css/font-awesome.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import HomeCardContent from "./cards/HomeCardContent";
import ModuleDisabled from "./cards/ModuleDisabled";
import ModuleEnabled from "./cards/ModuleEnabled";
import { getUser, isAdmin } from "./authHelper";
import "./index.css";
function Home() {
  const user = getUser();
  const { licenses } = user.cooperative;

  let image = "/images/no-image.png";
  if (getUser().cooperative && getUser().cooperative.logo) {
    console.log("Checking ...");
    image = getUser().cooperative.logo;
  }
  return (
    <SiteWrapper>
      <Page.Content
        className="page-content"
        title={getUser().cooperative.name + ` dashboard`}
      >
        <div className="father-grid grid">
          <div className="first-grid grid ">
            <div className="big-logo gird card home-card">
              <img
                src={image}
                style={{ height: 450, alignSelf: "center" }}
                alt="logo"
              />
            </div>
          </div>
          <div className="big-many-boxes grid ">
            <div className="small-child card home-card">
              {licenses.includes("INV") ? (
                <ModuleEnabled
                  to="/inventory"
                  module="Inventory"
                  icon="codepen"
                />
              ) : (
                <ModuleDisabled module="Inventory" icon="codepen" />
              )}
            </div>
            <div className="small-child card home-card">
              {licenses.includes("CRM") ? (
                <ModuleEnabled
                  to="/crm"
                  module="Customer Relationship Management "
                  icon="laptop"
                />
              ) : (
                <ModuleDisabled
                  module="Customer Relationship Management "
                  icon="laptop"
                />
              )}
            </div>
            <div className="small-child card home-card">
              {licenses.includes("HRM") ? (
                <ModuleEnabled
                  to="/hrm"
                  module="Human Resource Management "
                  icon="users"
                />
              ) : (
                <ModuleDisabled
                  module="Human Resource Management "
                  icon="users"
                />
              )}
            </div>
            <div className="small-child card home-card">
              {licenses.includes("KYC") ? (
                <ModuleEnabled
                  to="/kyc"
                  module="Know Your Customer"
                  icon="smile-o"
                />
              ) : (
                <ModuleDisabled module="Know Your Customer" icon="smile-o" />
              )}
            </div>
            <div className="small-child card home-card">
              {isAdmin() ? (
                <ModuleEnabled
                  to="/users"
                  module="Account Management"
                  icon="database"
                />
              ) : (
                <ModuleDisabled module="Account Management" icon="database" />
              )}
            </div>
            <div className="small-child card home-card">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://pdfhost.io/v/OydjMlfSL_Uhuru_pro_ERP_User_manualdocx"
              >
                <HomeCardContent module="Help" icon="question" />
              </a>
            </div>
          </div>
          {/* <div className="last-grid grid ">
            <div className="medium-child card home-card home-card-notifications">
              <div style={{ padding: 20 }}>
                <strong style={{ fontSize: 12 }}>ACCOUNT COMPLETION</strong>
                <div style={{ textAlign: "right" }}>
                  <Text.Small muted>42%</Text.Small>
                </div>
                <Progress size="xs">
                  <Progress.Bar color="danger" width={42} />
                </Progress>
                <div style={{ paddingTop: 18 }}>
                  <Form.Group label="Completed Steps">
                    <Form.Checkbox
                      disabled
                      checked
                      label="General Information"
                      name="example-radios"
                      value="option1"
                    />
                    <Form.Checkbox
                      disabled
                      label="Invite users"
                      name="example-radios"
                      value="option2"
                    />
                    <Form.Checkbox
                      disabled
                      label="Set-up stores"
                      name="example-radios"
                      value="option3"
                    />
                    <Form.Checkbox
                      disabled
                      label="Configure permissions"
                      name="example-radios2"
                      value="option4"
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="medium-child  home-card ">
              <Alert type="primary">
                <strong>
                  <Icon prefix="fa" name="lightbulb-o" /> Tip
                </strong>
                <br />
                <span style={{ fontSize: 12 }}>
                  Remember to Trigger weekly backups with encryption turned on
                </span>
              </Alert>
            </div>
          </div> */}
        </div>
      </Page.Content>
    </SiteWrapper>
  );
}

export default Home;
