import React, { useEffect, useState } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import useForm from "../effects/useForm";
import { post, put } from "../httpUtils";
import fetchCooperative from "./fetchCooperatives";
import { isSuperAdmin } from "../authHelper";
import { registerGlobalModal } from "../Platform/ModalManager";
import { useToasts } from "react-toast-notifications";

function CreateUserForm(props) {
  const { addToast } = useToasts();
  const { user } = props;
  const {
    values,
    handleChange,
    handleSubmit,
    reset,
    setInitialValues,
    manualChange,
  } = useForm(submit);
  useEffect(() => {
    if (user && user !== null && Object.keys(user).length) {
      setInitialValues(user);
    }
    //eslint-disable-next-line
  }, [user]);
  const [errors, setErrors] = useState([]);
  const { results: cooperatives } = fetchCooperative();

  const isEdit = user && user !== null && Object.keys(user).length;
  const currentUserValues = isEdit ? values : user || {};

  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/users`, formValues);
      } else {
        await put(`/users/${user._id}`, formValues);
      }
      addToast("User Created Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      console.log(e);
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onExit={() => reset()}
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!isEdit ? "Create user" : `Edit ${currentUserValues.fullname}`}
          </Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form.FieldSet className="New user">
            {errors.map((error) => (
              <Alert type="danger">{error}</Alert>
            ))}
            <Grid>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Full Name">
                    <Form.Input
                      name="fullname"
                      type="text"
                      onChange={handleChange}
                      value={currentUserValues.fullname}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Email">
                    <Form.Input
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={currentUserValues.email}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Password">
                    <Form.Input
                      name="password"
                      type="password"
                      onChange={handleChange}
                      value={currentUserValues.password}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Select
                    label="User Type"
                    name="type"
                    onChange={handleChange}
                    value={currentUserValues.type}
                  >
                    <option>Select user type</option>
                    {isSuperAdmin() && (
                      <option value="super-admin">Super admin</option>
                    )}
                    <option value="admin">Admin</option>
                    <option value="customer">User</option>
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                {isSuperAdmin() && (
                  <Grid.Col width={12}>
                    <Form.Group label="Cooperative">
                      <Select
                        name="cooperative"
                        placeholder="Select cooperative"
                        searchBy="cooperative"
                        valueField="_id"
                        labelField="name"
                        options={cooperatives}
                        onChange={(values) => {
                          if (values.length) {
                            manualChange({
                              name: "cooperative",
                              value: values[0]._id,
                            });
                          }
                        }}
                      />
                    </Form.Group>
                  </Grid.Col>
                )}
                <Grid.Col width={12}>
                  <Form.Group label="Photo">
                    <Form.FileInput
                      name="avatar"
                      type="file"
                      accept="image/jpg"
                      onChange={(e) => {
                        let files = e.target.files;
                        let file = files[0];
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          const event = {
                            persist() {},
                            target: {
                              name: "avatar",
                              value: reader.result,
                            },
                          };
                          handleChange(event);
                        };
                      }}
                    ></Form.FileInput>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!isEdit ? `Add User` : `Update User`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
registerGlobalModal("CreateUserForm", CreateUserForm);
export default CreateUserForm;
