import { BACKEND_URL } from "./config";
import { isAuthenticated, getToken } from "./authHelper";
function getHeaders() {
  let headers = { "Content-Type": "application/json" };
  if (isAuthenticated())
    headers = { ...headers, Authorization: `Bearer ${getToken()}` };

  return headers;
}
async function post(url, data) {
  const response = await fetch(`${BACKEND_URL}${url}`, {
    method: "POST",
    cache: "no-cache",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  if (response.status === 500 || response.status === 400) {
    const err = await response.json();
    throw err;
  }
  return response.json();
}
async function put(url, data) {
  const response = await fetch(`${BACKEND_URL}${url}`, {
    method: "PUT",
    cache: "no-cache",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  if (response.status === 500 || response.status === 400) {
    const err = await response.json();
    throw err;
  }
  return response.json();
}
async function softDelete(url, data) {
  const response = await fetch(`${BACKEND_URL}${url}`, {
    method: "DELETE",
    cache: "no-cache",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
  if (response.status === 500) {
    const err = await response.json();
    throw err;
  }
}
async function get(url) {
  const response = await fetch(`${BACKEND_URL}${url}`, {
    headers: getHeaders(),
  });
  return response.json();
}

export { post, put, get, softDelete };
