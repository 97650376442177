import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import Home from "./App";
import Login from "./Login";
import Inventory from "./Inventory";
import Users from "./Users";
import Kyc from "./Kyc";
import Crm from "./Crm";
import Hrm from "./HRM";
import AuthenticatedRoute from "./AuthenticatedRoute";

function App() {
  return (
    <ToastProvider autoDismiss={true} >
      <Router>
        <Route exact path="/" component={Login} />
        <AuthenticatedRoute path="/inventory" component={Inventory} />
        <AuthenticatedRoute exact path="/home" component={Home} />
        <AuthenticatedRoute path="/users" component={Users} />
        <AuthenticatedRoute path="/kyc" component={Kyc} />
        <AuthenticatedRoute path="/crm" component={Crm} />
        <AuthenticatedRoute path="/hrm" component={Hrm} />
      </Router>
    </ToastProvider>
  );
}

export default App;
