import React, { useEffect, useState } from "react";
import { Form, Table, Button } from "tabler-react";
import Select from "react-dropdown-select";
import fetchProductCategories from "../../Store/fetchProductCategories";
import { formatNoCurrency } from "../../../MoneyFormatter";

const SingleItem = ({
  index,
  handleInputChange,
  values,
  store,
  handleRemoveFields,
}) => {
  const { results: productCategories } = fetchProductCategories([], 0, 1000);
  const [productItems, setProductItems] = useState([]);
  useEffect(() => {
    if (store) {
      let availableProducts =
        productCategories.items &&
        productCategories.items.filter((i) => {
          return i.currentStock.find(
            (item) => item && item.store === store && item.count > 0
          );
        });
      setProductItems(availableProducts || []);
    }
  }, [productCategories, store]);
  function handleOnChange(event) {
    event.persist();
    // recalculate total
    handleChangeNoEvent(event.target.name, event.target.value);
  }
  function handleChangeNoEvent(name, value) {
    handleMultipleUpdate([{ name, value }]);
  }
  function handleMultipleUpdate(changes) {
    let updatedValues = {
      ...values,
    };
    for (const update of changes) {
      updatedValues = { ...updatedValues, [update.name]: update.value };
    }
    handleInputChange(index, {
      ...updatedValues,
      total: calculateTotal(updatedValues),
    });
  }
  return (
    <Table.Row>
      <Table.Col colSpan={2}>
        <Select
          placeholder="Select Item or Search Item Name"
          searchBy="name"
          valueField="_id"
          labelField="name"
          options={productItems || []}
          onChange={function (selected) {
            if (selected.length) {
              handleMultipleUpdate([
                { name: "item", value: selected[0]._id },
                { name: "unitCost", value: selected[0].price },
              ]);
            }
          }}
        />
      </Table.Col>
      <Table.Col>
        <Form.Input
          min="0"
          type="number"
          name="quantity"
          value={values && values.quantity}
          onChange={handleOnChange}
        />
      </Table.Col>
      <Table.Col>
        <Form.Input
          min="0"
          type="number"
          name="unitCost"
          value={values && values.unitCost}
          onChange={handleOnChange}
        />
      </Table.Col>
      <Table.Col>
        <Form.Input
          max="100"
          min="0"
          type="number"
          name="percentageDiscount"
          value={values && values.discount}
          onChange={handleOnChange}
        />
      </Table.Col>
      <Table.Col>{values && formatNoCurrency(values.total)}</Table.Col>
      <Table.Col>
        <Button
          icon="minus"
          onClick={(e) => {
            e.preventDefault();
            handleRemoveFields(index);
          }}
          color="danger"
          outline
        />
      </Table.Col>
    </Table.Row>
  );
};
export function calculateTotal({
  quantity = 0,
  unitCost = 0,
  percentageDiscount = 0,
}) {
  const totalCost = quantity * unitCost;
  const finalCost = totalCost - (percentageDiscount / 100) * totalCost;
  return finalCost > 0 ? finalCost : 0;
}

export default SingleItem;
