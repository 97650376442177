import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
function useFetchCustomers(additionalEvents = []) {
  const { error, loading, results } = useDataFetchWithPusher("/kyc", [
    ...additionalEvents,
    "customer-created"
  ]);

  return {
    error,
    loading,
    results
  };
}
export default useFetchCustomers;
