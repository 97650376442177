import * as React from "react";

import { Page, Grid } from "tabler-react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SiteWrapper from "../SiteWrapper";
import InventorySummary from "./InventorySummary";
import PurchaseSummary from "./PurchaseSummary";
import SalesSummary from "./SalesSummary";
import StoreList from "./Store";
import Stores from "./Store/StoresList";
import SalesDashboard from "./Sales";
import SalesReturnsList from "./Sales/SalesReturnsList";
import ListOfPurchasesDamages from "./Purchases/DamagesList";
import PurcahsesDashboard from "./Purchases";
import SupplierList from "./Purchases/SupplierList";
import CategoriesList from "./Store/CategoriesList";
import ProductCategoriesForm from "./Store/ProductCategoriesForm";
import TransferStockForm from "./Store/TransferStockForm";
import CategoriesForm from "./Store/CategoriesForm";
import SupplierForm from "./Purchases/SupplierForm";
import RecordDamagesForm from "./Purchases/RecordDamagesForm";
import RecordSalesReturnsForm from "./Sales/RecordSalesReturnsForm";
import EmployeeForm from "../HRM/Employees/EmployeeForm";
import Terminate from "../HRM/Employees/Terminate";
import StoreForm from "../Inventory/Store/StoreForm";
import TransfersList from "../Inventory/Store/TransfersList";
import { registerGlobalModal } from "../Platform/ModalManager";
registerGlobalModal("ProductCategoriesForm", ProductCategoriesForm);
registerGlobalModal("CategoriesForm", CategoriesForm);
registerGlobalModal("SupplierForm", SupplierForm);
registerGlobalModal("StoreForm", StoreForm);
registerGlobalModal("EmployeeForm", EmployeeForm);
registerGlobalModal("Terminate", Terminate);
registerGlobalModal("RecordDamagesForm", RecordDamagesForm);
registerGlobalModal("TransferStockForm", TransferStockForm);
registerGlobalModal("RecordSalesReturnsForm", RecordSalesReturnsForm);

function Home() {
  let { path } = useRouteMatch();
  return (
    <SiteWrapper module="inventory">
      <Switch>
        <Route exact path={path}>
          <Page.Content title="Inventory">
            <Grid.Row cards={true}>
              <InventorySummary />
              <PurchaseSummary />
              <SalesSummary />
            </Grid.Row>
          </Page.Content>
        </Route>
        <Route exact path={`${path}/store`} component={StoreList} />
        <Route exact path={`${path}/manage-stores`} component={Stores} />
        <Route exact path={`${path}/sales`} component={SalesDashboard} />
        <Route exact path={`${path}/categories`} component={CategoriesList} />
        <Route
          exact
          path={`${path}/damages`}
          component={ListOfPurchasesDamages}
        />
        <Route exact path={`${path}/returns`} component={SalesReturnsList} />
        <Route
          exact
          path={`${path}/purchases`}
          component={PurcahsesDashboard}
        />
        <Route exact path={`${path}/suppliers`} component={SupplierList} />
        <Route exact path={`${path}/transfers`} component={TransfersList} />
      </Switch>
    </SiteWrapper>
  );
}

export default Home;
