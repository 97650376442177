import * as React from "react";
import { Grid, Card, Table, Button, Page } from "tabler-react";
import SiteWrapper from "../SiteWrapper";
import CooperativeList from "./CooperativeList";
import { softDelete } from "../httpUtils";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import { showModal, showConfirmation } from "../Platform/events";
import { registerGlobalModal } from "../Platform/ModalManager";
import CreateCooperativeForm from "./CreateCooperative";
import CreateUserForm from "./CreateUserForm";
import PaginatedTable from "../Platform/PaginatedTable";
registerGlobalModal("CreateCooperative", CreateCooperativeForm);
registerGlobalModal("CreateUserForm", CreateUserForm);
function Users(props) {
  let { path } = useRouteMatch();
  let renderRow = (i) => {
    return (
      <>
        <Table.Col>{i.fullname}</Table.Col>
        <Table.Col>{i.cooperative && i.cooperative.name}</Table.Col>
        <Table.Col>{i.email}</Table.Col>
        <Table.Col>
          <Button
            color="danger"
            icon="fe fe-trash-2"
            className="ml-2 pull-right"
            size="sm"
            onClick={() => {
              showConfirmation({
                text: `Are you sure you want to delete ${i.fullname} ?`,
                onConfirm() {
                  softDelete(`/users/${i._id}`);
                },
              });
            }}
          ></Button>{" "}
          <Button
            color="secondary"
            icon="fe fe-edit"
            className="ml-2 pull-right"
            size="sm"
            onClick={() => showModal("CreateUserForm", { user: i })}
          >
            Edit
          </Button>
        </Table.Col>
      </>
    );
  };
  return (
    <SiteWrapper module="users">
      <Switch>
        <Route exact path={path}>
          <Page.Content title="Users" className="page-content">
            <Grid.Row>
              <Grid.Col width={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>List of users</Card.Title>
                    <Card.Options>
                      <Button
                        color="primary"
                        size="md"
                        onClick={() => showModal("CreateUserForm", null)}
                      >
                        <i className="fe fe-plus-circle mr-2"></i>Create User
                      </Button>
                    </Card.Options>
                  </Card.Header>
                  <PaginatedTable
                    columns={["Username", "Cooperative", "Email", ""]}
                    url="/users"
                    refetchEvents={["user-updated"]}
                    renderRow={renderRow}
                  />
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
        </Route>
        <Route exact path={`${path}/cooperative`} component={CooperativeList} />
      </Switch>
    </SiteWrapper>
  );
}

export default Users;
