import * as React from "react";
// import { Page } from "tabler-react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SiteWrapper from "../SiteWrapper";
// import DashBoard from "./Dashboard";
import Employees from "./Employees";
import Attendance from "./Attendance";
import Payroll from "./Payroll";
import Performance from "./Performance";
import Questions from "./Performance/QuestionsList";
import Leave from "./Leave";
import Departments from "./Departments";
function Home() {
  let { path } = useRouteMatch();
  return (
    <SiteWrapper module="hrm">
      <Switch>
        <Route exact path={path}>
          <Employees />
        </Route>
        <Route exact path={`${path}/departments`} component={Departments} />
        <Route exact path={`${path}/attendance`} component={Attendance} />
        <Route exact path={`${path}/payroll`} component={Payroll} />
        <Route exact path={`${path}/performance`} component={Performance} />
        <Route exact path={`${path}/performance`} component={Performance} />
        <Route exact path={`${path}/leave`} component={Leave} />
        <Route
          exact
          path={`${path}/performance/questions`}
          component={Questions}
        />
      </Switch>
    </SiteWrapper>
  );
}

export default Home;
