import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
function useFetchDepartment(additionalEvents = []) {
  const { error, loading, results } = useDataFetchWithPusher(
    "/hrm/departments?offset=0&&limit=1000",
    [...additionalEvents, "department-created"]
  );

  return {
    error,
    loading,
    results: results.items || [],
  };
}
export default useFetchDepartment;
