import React, { useState } from "react";

import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
import { post } from "../../httpUtils";
import fetchEmployees from "../Employees/fetchEmployees";
import { useToasts } from "react-toast-notifications";
import Select from "react-dropdown-select";

const AttendanceForm = (props) => {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit, manualChange } = useForm(submit);
  const { results: employees } = fetchEmployees();
  async function submit(data) {
    //validate
    try {
      await post("/hrm/attendance", data);
      addToast("Attendance Recorded Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Attendance</Modal.Title>{" "}
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet className="Attendance">
            <Grid>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Employee">
                    <Select
                      name="employee"
                      placeholder="Select employee"
                      searchBy="employee"
                      valueField="_id"
                      labelField="fullname"
                      options={employees}
                      onChange={(values) => {
                        if (values.length) {
                          manualChange({
                            name: "employee",
                            value: values[0]._id,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Time IN">
                    <Form.Input
                      name="timeIn"
                      type="time"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Time OUT">
                    <Form.Input
                      name="timeOut"
                      type="time"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Select
                    label="Status"
                    name="status"
                    onChange={handleChange}
                  >
                    <option>Select Status</option>
                    <option>Absent</option>
                    <option>Available</option>
                    <option>Field work</option>
                    <option>Leave</option>
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row></Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            {" "}
            <i className="fe fe-check mr-2"></i>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AttendanceForm;
