import React from "react";
import { Grid, StampCard } from "tabler-react";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
function InventorySummary() {
  const { loading, results } = useDataFetchWithPusher("/inventory", [
    "purchased-item-created",
    "sold-item-created",
    "store-item-updated",
    "store-item-created",
  ]);

  return (
    <React.Fragment>
      <Grid.Col sm={6} lg={3}>
        <StampCard
          color="blue"
          icon="dollar-sign"
          header={
            <span>
              {!loading && `${results.sales} `} <small>Sales</small>
            </span>
          }
        />
      </Grid.Col>
      <Grid.Col sm={6} lg={3}>
        <StampCard
          color="green"
          icon="shopping-cart"
          header={
            <span>
              {!loading && `${results.purchases} `} <small>Purchases</small>
            </span>
          }
        />
      </Grid.Col>
      <Grid.Col sm={6} lg={3}>
        <StampCard
          color="red"
          icon="download"
          header={
            <span>
              {!loading && `${results.suppliers} `} <small>Suppliers</small>
            </span>
          }
        />
      </Grid.Col>
      <Grid.Col sm={6} lg={3}>
        <StampCard
          color="yellow"
          icon="server"
          header={
            <span>
              {!loading && `${results.productCategories} `}
              <small>Products</small>
            </span>
          }
        />
      </Grid.Col>
    </React.Fragment>
  );
}

export default InventorySummary;
