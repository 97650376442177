import React, { useState } from "react";
import { Table, Button } from "tabler-react";
import SingleItem from "./SingleItem";
import formatMoney from "../../../MoneyFormatter";

const SalesItem = ({ onChange, store }) => {
  const [items, setItems] = useState([null]);
  const handleAddFields = () => {
    const values = [...items, null];
    setItems(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...items];
    values.splice(index, 1);
    setItems(values);
  };
  const handleInputChange = (index, data) => {
    const values = [...items];
    values[index] = data;
    setItems(values);
    onChange(values, calculateTotal(values));
  };

  return (
    <Table
      responsive
      className="card-table table-vcenter text-nowrap"
      headerItems={[
        { content: "Item" },
        {},
        { content: "Quantity" },
        { content: "Unit Cost" },
        { content: "Discount%" },
        { content: "Total" },
        {},
      ]}
    >
      <Table.Body>
        {items.map((i, index) => (
          <SingleItem
            key={index}
            values={i}
            index={index}
            store={store}
            handleRemoveFields={handleRemoveFields}
            handleInputChange={handleInputChange}
          />
        ))}
        <Table.Row>
          <Table.Col colSpan={7}>
            <Button
              icon="plus"
              color="primary"
              outline
              onClick={(e) => {
                e.preventDefault();
                handleAddFields();
              }}
            >
              Add new row
            </Button>
          </Table.Col>
        </Table.Row>
        <Table.Row>
          <Table.Col colSpan={5}></Table.Col>
          <Table.Col>
            <h4>
              <strong>Total: </strong>
            </h4>
          </Table.Col>
          <Table.Col>
            <h4>
              <strong>{formatMoney(calculateTotal(items))}</strong>
            </h4>
          </Table.Col>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
function calculateTotal(formItems) {
  return formItems.reduce((total, item) => {
    if (item) {
      return (total = item.total + total);
    }
    return total;
  }, 0);
}
export default SalesItem;
