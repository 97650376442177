import React from "react";
import { Badge } from "tabler-react";
import { getCurrentStock } from "./CurrentStockDisplay";
const StockStatusBadge = ({ product, store }) => {
  const status = getStockStatus(
    getCurrentStock(store, product.currentStock),
    product.minimum
  );
  return (
    <React.Fragment>
      {status === "In stock" && (
        <Badge color="success" className="mr-1 badges ">
          {status}
        </Badge>
      )}
      {status === "Out of stock" && (
        <Badge color="danger" className="mr-1 badges ">
          {status}
        </Badge>
      )}
      {status === "Re order" && (
        <Badge color="warning" className="mr-1 badges ">
          {status}
        </Badge>
      )}
    </React.Fragment>
  );
};

export default StockStatusBadge;

function getStockStatus(currentStock, minimum) {
  if (currentStock === 0) {
    return "Out of stock";
  }
  if (currentStock < minimum) {
    return "Re order";
  }
  if (currentStock >= minimum) {
    return "In stock";
  }
}
