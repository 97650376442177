import { useState } from "react";

const useForm = (callback) => {
  const [values, setValues] = useState({});

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    callback(values);
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };
  const manualChange = (obj) => {
    setValues((values) => ({
      ...values,
      [obj.name]: obj.value,
    }));
  };
  const addToObject = (name, obj) => {
    setValues((values) => ({
      ...values,
      [name]: { ...(values[name] || {}), ...obj },
    }));
  };
  const reset = () => {
    setValues({});
  };
  const setInitialValues = (values) => {
    setValues(values);
  };
  return {
    handleChange,
    addToObject,
    handleSubmit,
    manualChange,
    reset,
    values,
    setInitialValues,
  };
};

export default useForm;
