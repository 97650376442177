import React, { useState } from "react";
import Pagination from "react-js-pagination";
const Pagination2 = ({ onPageChange, totalItemsCount }) => {
  const [activePage, setActivePage] = useState(1);
  function onPageChangeL(pageNumber) {
    onPageChange(10 * (pageNumber - 1));
    setActivePage(pageNumber);
  }
  return (
    <div
      style={{
        borderTop: "1px solid #dee2e6",
        width: "100%",
        padding: "0.75rem",
        paddingBottom: 0
      }}
    >
      <Pagination
        activePage={activePage}
        itemsCountPerPage={10}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={3}
        itemClass="page-item"
        linkClass="page-link"
        onChange={onPageChangeL}
      />
    </div>
  );
};

export default Pagination2;
