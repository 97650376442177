import { useEffect } from "react";
import useDataFetch from "./useDataFetch";
import pusher from "../pusher";
function useDataFetchingWithPusher(dataSource, events = [], offset) {
  const { error, loading, results, reload } = useDataFetch(dataSource);
  useEffect(() => {
    events.forEach(element => {
      pusher.bind(element, function(data) {
        reload();
      });
    });
  }, [reload, events, offset]);
  return {
    error,
    loading,
    results
  };
}

export default useDataFetchingWithPusher;
