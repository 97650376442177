import React from "react";
import C3Chart from "react-c3js";
import { Grid, Card, Table, colors } from "tabler-react";
import moment from "moment";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
const emptyArr = Array(15).fill(0);
const graphMeta = {
  columns: [
    // each columns data
    ["data1", ...emptyArr],
  ],
  type: "area", // default type of chart
  groups: [["data1", "data2", "data3"]],
  colors: {
    data1: colors["blue"],
  },
  names: {
    // name of each serie
    data1: "Sales",
  },
};

const SalesSummary = () => {
  const { loading, results } = useDataFetchWithPusher(
    "/inventory/recent-sales",
    ["sold-item-created"]
  );
  const { loadingGraph, results: graphResults } = useDataFetchWithPusher(
    "/inventory/sales-graph",
    ["sold-item-created"]
  );
  const data = loadingGraph
    ? graphMeta
    : { ...graphMeta, columns: [["data1", ...graphResults]] };
  return (
    <Grid.Col lg={6}>
      <Card>
        <Card.Header>
          <Card.Title>Sales</Card.Title>
        </Card.Header>
        <C3Chart
          style={{ height: "10rem" }}
          data={data}
          axis={{
            y: {
              padding: {
                bottom: 0,
              },
              show: false,
              tick: {
                outer: false,
              },
            },
            x: {
              padding: {
                left: 0,
                right: 0,
              },
              show: false,
            },
          }}
          legend={{
            position: "inset",
            padding: 0,
            inset: {
              anchor: "top-left",
              x: 20,
              y: 8,
              step: 10,
            },
          }}
          tooltip={{
            format: {
              title: function (x) {
                return "";
              },
            },
          }}
          padding={{
            bottom: 0,
            left: -1,
            right: -1,
          }}
          point={{
            show: false,
          }}
        />
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Number</Table.ColHeader>
              <Table.ColHeader />
              <Table.ColHeader>Quantity</Table.ColHeader>
              <Table.ColHeader>Date</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading && !results.length && (
              <Table.Row>
                <Table.Col>Loading...</Table.Col>
              </Table.Row>
            )}
            {!loading && !results.length && (
              <Table.Row>
                <Table.Col>No results found</Table.Col>
              </Table.Row>
            )}
            {results.map((result) => (
              <Table.Row>
                <Table.Col colSpan={2}>{result.receiptNo}</Table.Col>
                <Table.Col>{result.items.length}</Table.Col>
                <Table.Col className="text-nowrap">
                  {moment(result.date).format("MMM Do YYYY")}
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </Grid.Col>
  );
};

export default SalesSummary;
