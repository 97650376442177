import React from "react";
import { Badge } from "tabler-react";
const StatusBadge = ({ status }) => {
  return (
    <React.Fragment>
      {status === "declined" && (
        <Badge color="danger" className="mr-1 badges ">
          {status}
        </Badge>
      )}
      {status === "approved" && (
        <Badge color="success" className="mr-1 badges ">
          {status}
        </Badge>
      )}
      {status === "pending" && (
        <Badge color="primary" className="mr-1 badges ">
          {status}
        </Badge>
      )}
    </React.Fragment>
  );
};

export default StatusBadge;
