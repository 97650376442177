import React, { useEffect, useState } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
import { post, put } from "../../httpUtils";
import { useToasts } from "react-toast-notifications";

function DepartmentForm(props) {
  const { addToast } = useToasts();
  const { values, handleChange, handleSubmit, reset, setInitialValues } =
    useForm(submit);
  const { department } = props;
  useEffect(() => {
    if (department && department !== null && Object.keys(department).length) {
      setInitialValues(props.department);
    }
    //eslint-disable-next-line
  }, [props.department, department]);
  const [errors, setErrors] = useState([]);
  const isEdit =
    department && department !== null && Object.keys(department).length;
  const currentValues = isEdit ? values : department || {};

  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/hrm/departments`, formValues);
      } else {
        await put(`/hrm/departments/${department._id}`, formValues);
      }
      addToast("Department Created Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      console.log(e);
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onExit={() => reset()}
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!isEdit ? "Create department" : `Edit ${currentValues.name}`}
          </Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form.FieldSet className="New department">
            {errors.map((error) => (
              <Alert type="danger">{error}</Alert>
            ))}
            <Grid>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Department Name">
                    <Form.Input
                      name="name"
                      type="text"
                      onChange={handleChange}
                      value={currentValues.name}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Description">
                    <Form.Textarea
                      name="description"
                      onChange={handleChange}
                      value={currentValues.description}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!isEdit ? `Add Department` : `Edit Department`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
export default DepartmentForm;
