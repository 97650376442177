import React, { useState } from "react";
import { Dropdown } from "tabler-react";
import SalesHistory from "./SalesHistory";
import SalesByCategory from "./SalesByCategory";
import SalesByCustomer from "./SalesByCustomer";
import InvoiceHistory from "./InvoiceHistory";
import DeletedSales from "./DeletedSales";
function Widget() {
  const [visible, setVisible] = useState(null);
  return (
    <>
      <SalesHistory
        show={visible === "sales-history"}
        onHide={() => setVisible(null)}
      ></SalesHistory>
      <DeletedSales
        show={visible === "deleted-sales"}
        onHide={() => setVisible(null)}
      ></DeletedSales>
      <SalesByCategory
        show={visible === "sales-by-category"}
        onHide={() => setVisible(null)}
      />
      <SalesByCustomer
        show={visible === "sales-by-customer"}
        onHide={() => setVisible(null)}
      />
      <InvoiceHistory
        show={visible === "invoice-history"}
        onHide={() => setVisible(null)}
      />

      <Dropdown
        type="button"
        toggle={false}
        color="primary"
        arrow
        triggerContent={
          <>
            <i className="fa fa-cube"></i> Reports{" "}
            <i className="fa fa-caret-down"></i>
          </>
        }
        itemsObject={[
          {
            value: "Sales History",
            onClick: () => setVisible("sales-history"),
          },
          { isDivider: true },
          {
            value: "Invoice History",
            onClick: () => setVisible("invoice-history"),
          },
          { isDivider: true },
          {
            value: "Sales by Category",
            onClick: () => setVisible("sales-by-category"),
          },
          { isDivider: true },
          {
            value: "Sales by Customer",
            onClick: () => setVisible("sales-by-customer"),
          },
          { isDivider: true },
          {
            value: "Deleted Sales",
            onClick: () => setVisible("deleted-sales"),
          },
        ]}
      />
    </>
  );
}

export default Widget;
