import React, { useState, useEffect } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
// import fetchSupplier from "../Purchases/effects/fetchSupplier";
import fetchCategories from "./fetchCategories";
import { post, put } from "../../httpUtils";
import moment from "moment";
import Select from "react-dropdown-select";
import { useToasts } from "react-toast-notifications";

function ProductCategoriesForm(props) {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit, values, manualChange, setInitialValues } =
    useForm(submit);
  const { item } = props;
  useEffect(() => {
    if (
      typeof item !== "undefined" &&
      item !== null &&
      Object.keys(item).length
    ) {
      setInitialValues(item);
    }
    //eslint-disable-next-line
  }, [item]);
  const { results: categories } = fetchCategories();
  const isEdit =
    typeof item !== "undefined" && item !== null && Object.keys(item).length;
  const currentItemValues = isEdit ? values : item || {};
  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/inventory/store/items`, formValues);
      } else {
        await put(`/inventory/store/items/${item._id}`, formValues);
      }
      addToast("Product Created Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!item || item.name === undefined
              ? "Add new product"
              : `Edit ${item.name}`}
          </Modal.Title>

          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet>
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Product name">
                    <Form.Input
                      name="name"
                      placeholder="Input product name"
                      onChange={handleChange}
                      value={currentItemValues.name}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Product Code">
                    <Form.Input
                      name="code"
                      type="string"
                      onChange={handleChange}
                      value={currentItemValues.code}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Purchase price">
                    <Form.Input
                      placeholder="Price product will be bought"
                      name="cost"
                      type="number"
                      onChange={handleChange}
                      value={currentItemValues.cost}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Selling price">
                    <Form.Input
                      placeholder="Price product will be sold"
                      name="price"
                      type="number"
                      onChange={handleChange}
                      value={currentItemValues.price}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Packaging quantity">
                    <Form.Input
                      name="size"
                      placeholder="Package quantity of product e.g. 1 kg"
                      onChange={handleChange}
                      value={currentItemValues.size}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Units"
                    name="unit"
                    onChange={handleChange}
                    value={currentItemValues.unit}
                  >
                    <option value="">Select units for item</option>
                    <option value="Piece(s)">Pieces</option>
                    <option value="Carton(s)">Cartons</option>
                    <option value="Box(es)">Boxes</option>
                    <option value="Kg(s)">Kgs</option>
                    <option value="Litres(s)">Litres</option>
                    <option value="Bag(s)">Bags</option>
                    <option value="Units(s)">Units</option>
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Re-order level">
                    <Form.Input
                      placeholder="Stock level at which an alert to re-order is sent"
                      type="number"
                      name="minimum"
                      onChange={handleChange}
                      value={currentItemValues.minimum}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Category">
                    <Select
                      values={
                        currentItemValues.category
                          ? [currentItemValues.category]
                          : []
                      }
                      name="category"
                      placeholder="Select Category"
                      searchBy="category"
                      valueField="_id"
                      labelField="category"
                      options={categories}
                      onChange={(values) => {
                        if (values.length) {
                          manualChange({
                            name: "category",
                            value: values[0]._id,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Date">
                    <Form.Input
                      name="date"
                      disabled
                      value={moment().format("MMM Do YYYY")}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Form.Group label="Item Description">
                <Form.Textarea
                  placeholder="Input detailed description of the product"
                  name="description"
                  value={currentItemValues.description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => {
              props.onHide();
            }}
          >
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!item || !item.name ? `Add new item` : `Update item`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
export default ProductCategoriesForm;
