import React from "react";
import { Grid, Card, Table, Button, Page } from "tabler-react";
import moment from "moment";
import RecordTransactionForm from "./RecordTransactionForm";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
import formatMoney from "../MoneyFormatter";

function CustomerList(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const { loading, results: customers } = useDataFetchWithPusher(
    "/kyc/transactions",
    ["transaction-created"]
  );
  return (
    <Page.Content title="Transactions">
      <Grid.Row>
        <RecordTransactionForm
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>Transactions list</Card.Title>
              <Card.Options>
                <Button
                  color="primary"
                  size="md"
                  onClick={() => setModalShow(true)}
                >
                  <i className="fe fe-plus-circle mr-2"></i> Record transaction
                </Button>
              </Card.Options>
            </Card.Header>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap"
              headerItems={[
                { content: "Name" },
                { content: "Transaction ID" },
                { content: "Transaction Type" },
                { content: "Amount" },
                { content: "Date" },
              ]}
            >
              <Table.Body>
                {loading && !customers.length && (
                  <Table.Row>
                    <Table.Col colSpan="6">Loading...</Table.Col>
                  </Table.Row>
                )}
                {!loading && !customers.length && (
                  <Table.Row>
                    <Table.Col colSpan="6">No results found</Table.Col>
                  </Table.Row>
                )}
                {customers.map((i) => (
                  <Table.Row>
                    <Table.Col>{i.customer.name}</Table.Col>
                    <Table.Col>{i.transactionId}</Table.Col>
                    <Table.Col>{i.transactionType}</Table.Col>
                    <Table.Col>{formatMoney(i.amount)}</Table.Col>
                    <Table.Col>
                      {moment(i.date).format("MMM Do YYYY")}
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
}

export default CustomerList;
