import * as React from "react";

import { Page, Grid } from "tabler-react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SiteWrapper from "../SiteWrapper";
import KycSummary from "./KycSummary";
import CustomerList from "./CustomerList";
import TransactionList from "./TransactionList";
import NewCustomerForm from "./NewCustomerForm";
import { registerGlobalModal } from "../Platform/ModalManager";
registerGlobalModal("CustomerForm", NewCustomerForm);
function Kyc() {
  let { path } = useRouteMatch();
  return (
    <SiteWrapper module="kyc">
      <Switch>
        <Route exact path={path}>
          <Page.Content title="Kyc">
            <Grid.Row cards={true}>
              <KycSummary />
            </Grid.Row>

            <CustomerList />
          </Page.Content>
        </Route>
        <Route
          exact
          path={`${path}/transactions`}
          component={TransactionList}
        />
      </Switch>
    </SiteWrapper>
  );
}

export default Kyc;
