import React, { useState, useEffect } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
import { post, put } from "../../httpUtils";
import useNumberFetch from "../../effects/useNumberFetch";
import { useToasts } from "react-toast-notifications";

function AddSupplierForm(props) {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit, values, setInitialValues } =
    useForm(submit);
  const { item } = props;
  const isEdit =
    typeof item !== "undefined" && item !== null && Object.keys(item).length;
  const currentItemValues = isEdit ? values : item || {};
  useEffect(() => {
    if (
      typeof item !== "undefined" &&
      item !== null &&
      Object.keys(item).length
    ) {
      setInitialValues(item);
    }
    //eslint-disable-next-line
  }, [item]);
  const { results: nextNumber } = useNumberFetch("VO");
  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/inventory/purchases/suppliers`, formValues);
      } else {
        await put(`/inventory/purchases/suppliers/${item._id}`, formValues);
      }
      addToast("Supplier Created Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!item || item.name === undefined
              ? "Add new supplier"
              : `Edit ${item.name}`}
          </Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>

        <Modal.Body>
          <Form.FieldSet>
            {errors.map((error) => (
              <Alert type="danger">{error}</Alert>
            ))}
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Supplier Name">
                    <Form.Input
                      type="string"
                      name="companyName"
                      onChange={handleChange}
                      value={currentItemValues.companyName}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Telephone Number">
                    <Form.Input
                      type="number"
                      name="telephoneNumber"
                      onChange={handleChange}
                      value={currentItemValues.telephoneNumber}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="TIN">
                    <Form.Input
                      name="tin"
                      type="number"
                      maxlenght="10"
                      minlength="10"
                      placeholder="10 digits"
                      onChange={handleChange}
                      value={currentItemValues.tin}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Physical Address">
                    <Form.Input
                      type="string"
                      name="physicalAddress"
                      onChange={handleChange}
                      value={currentItemValues.physicalAddress}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Vender ID">
                    <Form.Input
                      name="vendorId"
                      onChange={handleChange}
                      value={
                        !item || item.companyName === undefined
                          ? nextNumber.number
                          : currentItemValues.vendorId
                      }
                      disabled
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="E-mail Address">
                    <Form.Input
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={currentItemValues.email}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!item || !item.companyName
              ? `Add new supplier`
              : `Update ${item.companyName}`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
export default AddSupplierForm;
