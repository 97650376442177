import React from "react";
import { Avatar, Grid, Card, Button, Table, Page } from "tabler-react";
import moment from "moment";
// import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
import EmployeeView from "./EmployeeView";
import PaginatedTable from "../../Platform/PaginatedTable";
import HrmSummary from "../HrmSummary";
import { softDelete } from "../../httpUtils";
import { showConfirmation, showModal } from "../../Platform/events";
import { registerGlobalModal } from "../../Platform/ModalManager";
import { isAdmin } from "../../authHelper";
registerGlobalModal("EmployeeView", EmployeeView);
const Employees = () => {
  let renderRow = (i) => {
    return (
      <React.Fragment>
        <Table.Col>
          <Avatar imageURL={i.avatar} />
        </Table.Col>
        <Table.Col>{i.employeeNo}</Table.Col>
        <Table.Col>{i.fullname}</Table.Col>
        <Table.Col>{i.department && i.department.name}</Table.Col>
        <Table.Col>{i.schedule}</Table.Col>
        <Table.Col>{moment(i.created).format("MMM Do YYYY")}</Table.Col>
        <Table.Col>
          {isAdmin() && (
            <Button
              color="danger"
              icon="fe fe-trash-2"
              className="ml-2 pull-right"
              size="sm"
              onClick={() => {
                showConfirmation({
                  text: `Are you sure you want to delete ${i.fullname}?`,
                  onConfirm() {
                    softDelete(`/hrm/${i._id}`);
                  },
                });
              }}
            ></Button>
          )}
          {isAdmin() && getTerminatedButton(i)}
          <Button
            icon="fe fe-eye"
            color="secondary"
            size="sm"
            className="ml-2 pull-right"
            onClick={() => {
              showModal("EmployeeView", { profile: i });
            }}
          >
            View employee
          </Button>
          <Button
            icon="fe fe-edit"
            color="secondary"
            size="sm"
            className="ml-2 pull-right"
            onClick={() => showModal("EmployeeForm", { item: i })}
          >
            Edit
          </Button>
        </Table.Col>
      </React.Fragment>
    );
  };
  return (
    <Page.Content className="page-content" title="Employees">
      <Grid>
        <Grid.Row>
          <Grid.Col width={12}>
            <HrmSummary />
            <Card>
              <Card.Header>
                <Card.Title>Employees</Card.Title>
                <Card.Options>
                  <Button
                    color="primary"
                    size="md"
                    onClick={() => showModal("EmployeeForm", null)}
                  >
                    <i className="fe fe-plus-circle mr-2"></i> Add employee
                  </Button>
                </Card.Options>
              </Card.Header>
              <PaginatedTable
                columns={[
                  "Photo",
                  "Employee No.",
                  "Name",
                  "Department",
                  "Schedule",
                  "Member since",
                  "",
                ]}
                url="/hrm"
                rowStyle={(i) => ({
                  color: i.terminated.status === true ? "red" : "",
                })}
                refetchEvents={["employee-updated", "employee-created"]}
                renderRow={renderRow}
              />
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Page.Content>
  );
};

function getTerminatedButton(item) {
  if (item.terminated.status === true) {
    return;
  }
  return (
    <Button
      color="danger"
      icon="fe fe-x"
      className="ml-2 pull-right"
      size="sm"
      onClick={() => showModal("Terminate", { item })}
    >
      Terminate
    </Button>
  );
}

export default Employees;
