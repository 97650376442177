import React, { useState, useEffect } from "react";
// import FileBase64 from "react-file-base64";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../effects/useForm";
import { post, put } from "../httpUtils";
import { useToasts } from "react-toast-notifications";

const CreateCooperativeForm = (props) => {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);

  const { values, handleChange, handleSubmit, setInitialValues } =
    useForm(submit);
  const { cooperative } = props;
  useEffect(() => {
    if (
      cooperative &&
      cooperative !== null &&
      Object.keys(cooperative).length
    ) {
      setInitialValues(cooperative);
    }
    //eslint-disable-next-line
  }, [cooperative]);

  const isEdit =
    cooperative && cooperative !== null && Object.keys(cooperative).length;
  const currentUserValues = isEdit ? values : cooperative || {};
  async function submit(data) {
    //validate
    try {
      if (!isEdit) {
        await post(`/users/cooperatives`, data);
      } else {
        await put(`/users/cooperatives/${cooperative._id}`, data);
      }
      addToast("Cooperative Added Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!isEdit ? "Create cooperative" : `Edit ${currentUserValues.name}`}
          </Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet className="Add cooperative">
            <Grid>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Cooperative name">
                    <Form.Input
                      name="name"
                      type="string"
                      onChange={handleChange}
                      value={currentUserValues.name}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Country">
                    <Form.Input
                      name="country"
                      type="string"
                      onChange={handleChange}
                      value={currentUserValues.address}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="District">
                    <Form.Input
                      name="district"
                      type="string"
                      onChange={handleChange}
                      value={currentUserValues.address}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Address">
                    <Form.Input
                      name="address"
                      onChange={handleChange}
                      value={currentUserValues.address}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="P.O. Box">
                    <Form.Input
                      name="box"
                      onChange={handleChange}
                      value={currentUserValues.address}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Telephone">
                    <Form.Input
                      name="tel"
                      type="string"
                      onChange={handleChange}
                      value={currentUserValues.address}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Upload logo">
                    <Form.FileInput
                      name="logo"
                      type="file"
                      accept="image/jpg"
                      onChange={(e) => {
                        let files = e.target.files;
                        let file = files[0];
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          const event = {
                            persist() {},
                            target: {
                              name: "logo",
                              value: reader.result,
                            },
                          };
                          handleChange(event);
                        };
                      }}
                    ></Form.FileInput>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!isEdit ? "Create Cooperative" : "Update Cooperative"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default CreateCooperativeForm;
