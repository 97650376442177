import React, { useState, useEffect } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
import fetchStoreItems from "../Store/fetchProductCategories";
import fetchSupplier from "../Purchases/effects/fetchSupplier";
import fetchStores from "../Store/fetchStores";
import { post, put } from "../../httpUtils";
import { useToasts } from "react-toast-notifications";
import SearchableSelect from "../../Platform/SearchableSelect";

function RecordDamagesForm(props) {
  const { addToast } = useToasts();
  const { handleChange, manualChange, handleSubmit, setInitialValues, values } =
    useForm(submit);
  const [errors, setErrors] = useState([]);
  const { results: stores } = fetchStores();
  const { item } = props;
  const isEdit =
    typeof item !== "undefined" && item !== null && Object.keys(item).length;
  const currentItemValues = isEdit ? values : item || {};
  useEffect(() => {
    if (
      typeof item !== "undefined" &&
      item !== null &&
      Object.keys(item).length
    ) {
      setInitialValues(item);
    }
    //eslint-disable-next-line
  }, [item]);
  const { results: storeItems } = fetchStoreItems([], 0, 100);
  const { results: suppliers } = fetchSupplier();
  const productsMap =
    storeItems.items &&
    storeItems.items.reduce((p, c) => {
      if (c) {
        p[c._id] = c;
      }
      return p;
    }, {});
  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/inventory/purchases/damages`, formValues);
      } else {
        await put(`/inventory/purchases/damages/${item._id}`, formValues);
      }
      addToast("Damage Recorded Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!item || item.name === undefined
              ? "Record Purchases Return"
              : `Edit Purchases`}
          </Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form.FieldSet className="NewItem">
            {errors.map((error) => (
              <Alert type="danger">{error}</Alert>
            ))}
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <SearchableSelect
                    onChange={(e) => {
                      let product = productsMap[e.value];
                      manualChange({
                        name: "unitPrice",
                        value: product?.cost || 0,
                      });
                      manualChange({
                        name: "item",
                        value: e.value,
                      });
                    }}
                    label="Item"
                    name="item"
                    options={
                      storeItems.items &&
                      storeItems.items.map((i) => ({
                        value: i._id,
                        display: i.name,
                      }))
                    }
                    defaultText="Select Item"
                  />
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label="Unit price">
                    <Form.Input
                      type="number"
                      name="unitPrice"
                      onChange={handleChange}
                      value={values.unitPrice}
                      disabled
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label="Quantity">
                    <Form.Input
                      type="number"
                      name="quantity"
                      onChange={handleChange}
                      value={currentItemValues.quantity}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Select
                    label="Supplier"
                    name="supplier"
                    onChange={handleChange}
                    value={currentItemValues.supplier}
                  >
                    <option>Select supplier</option>
                    {suppliers.map((item) => (
                      <option value={item._id}>{item.companyName}</option>
                    ))}
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Invoice number">
                    <Form.Input
                      name="invoiceNumber"
                      onChange={handleChange}
                      value={currentItemValues.invoiceNumber}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Store"
                    name="store"
                    onChange={handleChange}
                  >
                    <option>Select Store</option>
                    {stores.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Form.Group label="Comment">
                <Form.Textarea name="comment" onChange={handleChange} />
              </Form.Group>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            Record damage
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RecordDamagesForm;
