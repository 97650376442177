import * as React from "react";

import { Page } from "tabler-react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SiteWrapper from "../SiteWrapper";
import QueriesList from "./QueriesList";

function Crm() {
  let { path } = useRouteMatch();
  return (
    <SiteWrapper module="crm">
      <Switch>
        <Route exact path={path}>
          <Page.Content title="Queries">
            <QueriesList />
          </Page.Content>
        </Route>
      </Switch>
    </SiteWrapper>
  );
}

export default Crm;
