import React, { useState, useEffect } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
import { post, put } from "../../httpUtils";
import { registerGlobalModal } from "../../Platform/ModalManager";
import { useToasts } from "react-toast-notifications";

function CategoriesForm(props) {
  const { addToast } = useToasts();
  const { handleChange, handleSubmit, setInitialValues, values } =
    useForm(submit);
  const [errors, setErrors] = useState([]);
  const { item } = props;
  const isEdit =
    typeof item !== "undefined" && item !== null && Object.keys(item).length;
  const currentItemValues = isEdit ? values : item || {};
  useEffect(() => {
    if (
      typeof item !== "undefined" &&
      item !== null &&
      Object.keys(item).length
    ) {
      setInitialValues(item);
    }
    //eslint-disable-next-line
  }, [item]);
  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/inventory/store/category`, formValues);
      } else {
        await put(`/inventory/store/category/${item._id}`, formValues);
      }
      addToast("Category Created Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!item || item.category === undefined
              ? "Add new category"
              : `Edit ${item.category}`}
          </Modal.Title>{" "}
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form.FieldSet className="NewItem">
            {errors.map((error) => (
              <Alert type="danger">{error}</Alert>
            ))}
            <Grid>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Category name">
                    <Form.Input
                      name="category"
                      onChange={handleChange}
                      value={currentItemValues.category}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={12}>
                  <Form.Group label="Category code">
                    <Form.Input
                      name="code"
                      onChange={handleChange}
                      value={currentItemValues.category}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={12}>
                  <Form.Group label="Category description">
                    <Form.Textarea
                      name="description"
                      onChange={handleChange}
                      value={currentItemValues.description}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!item || !item.category ? `Add new category` : `Update category`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
registerGlobalModal("CategoriesForm", CategoriesForm);
export default CategoriesForm;
