import React from "react";
import { Modal } from "react-bootstrap";
import { Button, Table, Avatar } from "tabler-react";
import { getUser } from "../../authHelper";
import moment from "moment";
import formatMoney from "../../MoneyFormatter";
import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";

const PaymentReport = (props) => {
  const { selected: transaction } = props;
  const { loading, results: payments } = useDataFetchWithPusher(
    `/hrm/payroll/employee/${getEmployeeId(props.selected)}`,
    []
  );
  const { cooperative } = getUser();
  return (
    <Modal
      {...props}
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <div className="text-center">
            <h3 className="text-center">Payment history</h3>
          </div>
        </Modal.Title>
        <Button color="primary" icon="printer">
          Print
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="well col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <Avatar
                    size="xxl"
                    imageURL={cooperative.logo}
                    alt="Cooperative logo"
                  />
                </div>
                <table className="table table-active">
                  <tbody>
                    <tr>
                      <th className="text-center">
                        Employee #:{" "}
                        <b>
                          {transaction.employee &&
                            transaction.employee.employeeNo}
                        </b>
                      </th>
                      <th className="text-center">
                        Full name:
                        <b>
                          {" "}
                          {transaction.employee &&
                            transaction.employee.fullname}
                        </b>
                      </th>
                      <th className="text-center">
                        Department:
                        <b>
                          {" "}
                          {transaction.employee && transaction.employee.name}
                        </b>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                  headerItems={[
                    { content: "Date" },
                    { content: "Gross" },
                    { content: "Tax" },
                    { content: "NSSF" },
                    { content: "Deductions" },
                    { content: "Net pay" },
                  ]}
                >
                  <Table.Body>
                    {loading && (
                      <Table.Row>
                        <Table.Col colSpan="6">Loading...</Table.Col>
                      </Table.Row>
                    )}
                    {!loading && !payments.length && (
                      <Table.Row>
                        <Table.Col colSpan="6">No payments found</Table.Col>
                      </Table.Row>
                    )}
                    {payments.map((i) => (
                      <Table.Row>
                        <Table.Col>
                          {moment(i.date).format("MMM Do YYYY")}
                        </Table.Col>
                        <Table.Col>{formatMoney(i.gross)}</Table.Col>
                        <Table.Col>{formatMoney(i.tax)}</Table.Col>
                        <Table.Col>{formatMoney(i.nssf)}</Table.Col>
                        <Table.Col>{formatMoney(i.deductions)}</Table.Col>
                        <Table.Col>
                          {formatMoney(
                            i.gross -
                              (i.advance + i.deductions + i.nssf + i.tax)
                          )}
                        </Table.Col>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" icon="x-circle" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
function getEmployeeId(payments) {
  return payments && payments.employee ? payments.employee.id : undefined;
}

export default PaymentReport;
