import React from "react";
import { Avatar, Grid, Card, Button, Table, Page } from "tabler-react";
import moment from "moment";
import { Link } from "react-router-dom";
// import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
import PerformanceForm from "./PerformanceForm";
// import AddQuestion from "./AddQuestion";
import ViewPerformance from "./ViewPerformanceView";
import PaginatedTable from "../../Platform/PaginatedTable";
import { registerGlobalModal } from "../../Platform/ModalManager";
import { showModal } from "../../Platform/events";

registerGlobalModal("PerformanceForm", PerformanceForm);
registerGlobalModal("ViewPerformance", ViewPerformance);

const Performance = () => {
  let renderRow = (i) => {
    return (
      <React.Fragment>
        <Table.Col>
          <Avatar URL={i.employee.avatar} />
        </Table.Col>

        <Table.Col>{i.employee.fullname}</Table.Col>

        <Table.Col>{i.score}</Table.Col>
        <Table.Col>{i.performedBy.fullname}</Table.Col>

        <Table.Col>{moment(i.created).format("MMM Do YYYY")}</Table.Col>
        <Table.Col>
          <Button
            icon="fe fe-eye"
            color="secondary"
            size="sm"
            className="ml-2 pull-right"
            onClick={() => showModal("ViewPerformance", { selected: i })}
          >
            View report
          </Button>
        </Table.Col>
      </React.Fragment>
    );
  };
  return (
    <Page.Content className="page-content" title="Performance">
      <Grid>
        <Grid.Row>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Performance</Card.Title>
                <Card.Options>
                  <Button
                    color="primary"
                    size="md"
                    onClick={() => showModal("PerformanceForm", null)}
                  >
                    <i className="fe fe-plus-circle mr-2"></i> Review employee
                  </Button>
                  <Link to="/hrm/performance/questions">
                    <Button color="success" size="md">
                      Manage questions
                    </Button>
                  </Link>
                </Card.Options>
              </Card.Header>
              <PaginatedTable
                columns={[
                  "Avatar",
                  "Name",
                  "Score",
                  "Performed by",
                  "Date of evaluation",
                  "",
                ]}
                url="/hrm/performance"
                refetchEvents={["performance-created"]}
                renderRow={renderRow}
              />
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Page.Content>
  );
};

export default Performance;
