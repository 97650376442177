import React from "react";
import { Button } from "react-bootstrap";
import Select from "react-dropdown-select";
import { Form } from "tabler-react";
import { showModal } from "./events";
const SearchableSelectWithAdd = ({
  label,
  onChange,
  modalType,
  defaultText,
  options,
}) => {
  return (
    <Form.InputGroup>
      <Form.Group label={label}>
        <Select
          placeholder={defaultText}
          searchBy="display"
          valueField="value"
          labelField="display"
          options={options || []}
          onChange={(selected) => {
            onChange(selected[0]);
          }}
        />
      </Form.Group>
      <Form.InputGroupAppend>
        <Button color="primary" onClick={() => showModal(modalType, null)}>
          +
        </Button>
      </Form.InputGroupAppend>
    </Form.InputGroup>
  );
};

export default SearchableSelectWithAdd;
