import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
function useFetchEmployees(additionalEvents = []) {
  const { error, loading, results } = useDataFetchWithPusher(
    "/hrm?limit=10000&offset=0",
    [...additionalEvents, "employee-created"]
  );

  return {
    error,
    loading,
    results: results.items || [],
  };
}
export default useFetchEmployees;
