import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button, Table, Avatar } from "tabler-react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { getUser } from "../../authHelper";
import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";

const AttendanceReport = (props) => {
  const componentRef = useRef();
  const { cooperative } = getUser();
  const { loading, results: attendances } = useDataFetchWithPusher(
    `/hrm/attendance/employee/${getEmployeeId(props.selected)}`,
    []
  );
  const { selected: attendance } = props;
  return (
    <Modal
      {...props}
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          <div className="text-center">
            <h3 className="text-center">Attendance history</h3>
          </div>
        </Modal.Title>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" icon="printer">
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="well col-md-12">
            <div className="printDiv" ref={componentRef}>
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <Avatar
                      size="xxl"
                      imageURL={cooperative.logo}
                      alt="Cooperative logo"
                    />
                  </div>
                  <table className="table table-active">
                    <tbody>
                      <tr>
                        <th className="text-center">
                          Employee #:{" "}
                          <b>
                            {attendance.employee &&
                              attendance.employee.employeeNo}
                          </b>
                        </th>
                        <th className="text-center">
                          Full name:{" "}
                          <b>
                            {attendance.employee &&
                              attendance.employee.fullname}
                          </b>
                        </th>
                        <th className="text-center">
                          Department:{" "}
                          <b>
                            {attendance.employee &&
                              attendance.employee.department.name}
                          </b>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap"
                    headerItems={[
                      { content: "Date" },
                      { content: "Time in" },
                      { content: "Time out" },
                      { content: "Status" },
                    ]}
                  >
                    <Table.Body>
                      {loading && (
                        <Table.Row>
                          <Table.Col colSpan="6">Loading...</Table.Col>
                        </Table.Row>
                      )}
                      {!loading && !attendances.length && (
                        <Table.Row>
                          <Table.Col colSpan="6">
                            No attendances found
                          </Table.Col>
                        </Table.Row>
                      )}
                      {attendances.map((i) => (
                        <Table.Row>
                          <Table.Col>
                            {moment(i.date).format("MMM Do YYYY")}
                          </Table.Col>
                          <Table.Col>{i.timeIn}</Table.Col>
                          <Table.Col>{i.timeOut}</Table.Col>
                          <Table.Col>{i.status}</Table.Col>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" icon="x-circle" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
function getEmployeeId(attendance) {
  return attendance && attendance.employee ? attendance.employee.id : undefined;
}
export default AttendanceReport;
