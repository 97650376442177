import useDataFetchWithPusher from "./useDataFetchWithPusher";
function useFetchStoreItems(type) {
  const { error, loading, results } = useDataFetchWithPusher(
    `/number-generator/?type=${type}`,
    []
  );

  return {
    error,
    loading,
    results
  };
}
export default useFetchStoreItems;
