import React from "react";
import { Card, Table, Button, Page } from "tabler-react";
// import AddItemForm from "./SupplierForm";
import SupplierHistory from "./PurchasesReports/SupplierHistory";
import fetchSupplier from "./effects/fetchSupplier";
import { softDelete } from "../../httpUtils";
import { showModal, showConfirmation } from "../../Platform/events";
import { isAdmin } from "../../authHelper";

function StoreList() {
  const [supplierHistoryModalShow, setSupplierHistoryModalShow] =
    React.useState(false);
  const [selectedItem, setSelected] = React.useState(null);
  const { loading, results: suppliers } = fetchSupplier();

  return (
    <Page.Content title="Suppliers" className="page-content">
      <SupplierHistory
        show={supplierHistoryModalShow}
        selected={selectedItem}
        onHide={() => {
          setSupplierHistoryModalShow(false);
        }}
      />
      <Card>
        <Card.Header>
          <Card.Title>Suppliers List</Card.Title>
          <Card.Options>
            <Button
              color="primary"
              size="md"
              onClick={() => showModal("SupplierForm", null)}
            >
              <i className="fe fe-plus-circle mr-2"></i> Add new supplier
            </Button>
          </Card.Options>
        </Card.Header>

        <Table
          responsive
          className="card-table table-vcenter text-nowrap"
          headerItems={[
            { content: "Vender ID" },
            { content: "Name" },
            { content: "Mobile" },
            { content: "Address" },
            {},
          ]}
        >
          <Table.Body>
            {loading && !suppliers.length && (
              <Table.Row>
                <Table.Col colSpan="5">Loading...</Table.Col>
              </Table.Row>
            )}
            {!loading && !suppliers.length && (
              <Table.Row>
                <Table.Col colSpan="5">No results found</Table.Col>
              </Table.Row>
            )}
            {suppliers.map((i) => (
              <Table.Row>
                <Table.Col>{i.vendorId}</Table.Col>
                <Table.Col>{i.companyName}</Table.Col>
                <Table.Col>{i.telephoneNumber}</Table.Col>
                <Table.Col>{i.physicalAddress}</Table.Col>

                <Table.Col>
                  {isAdmin() && (
                    <Button
                      color="danger"
                      icon="fe fe-trash-2"
                      className="ml-2 pull-right"
                      size="sm"
                      onClick={() => {
                        showConfirmation({
                          text: `Are you sure you want to delete ${i.companyName}?`,
                          onConfirm() {
                            softDelete(
                              `/inventory/purchases/suppliers/${i._id}`
                            );
                          },
                        });
                      }}
                    ></Button>
                  )}
                  <Button
                    className="ml-2 pull-right"
                    color="secondary"
                    icon="fe fe-edit"
                    size="sm"
                    onClick={() => showModal("SupplierForm", { item: i })}
                  >
                    Edit
                  </Button>
                  <Button
                    icon="fe fe-eye"
                    color="secondary"
                    size="sm"
                    className="ml-2 pull-right"
                    onClick={() => {
                      setSelected(i);
                      setSupplierHistoryModalShow(true);
                    }}
                  >
                    History
                  </Button>
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </Page.Content>
  );
}

export default StoreList;
