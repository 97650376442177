import { useState, useEffect } from "react";
import { get } from "../httpUtils";
function useDataFetching(dataSource) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const json = await get(dataSource);
        if (json) {
          setLoading(false);
          setResults(json);
        }
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }

      setLoading(false);
    }

    fetchData();
  }, [dataSource, loading]);
  const reload = () => {
    setLoading(true);
  };
  return {
    error,
    loading,
    results,
    reload,
  };
}

export default useDataFetching;
