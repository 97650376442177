import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button, Card, Comment, Grid } from "tabler-react";
import { put } from "../httpUtils";
import moment from "moment";

const Query = (props) => {
  const [comment, setComment] = React.useState(null);
  const [comments, setComments] = React.useState([]);
  useEffect(() => {
    setComments((state) => props.selected.comments);
  }, [props.selected]);
  async function submit() {
    if (comment != null || comment.trim() !== "") {
      await put(`/crm/${props.selected._id}/comment`, { comment });
      setComments((state) => [...comments, comment]);
      setComment("");
    }
  }
  async function markAsCompleted() {
    await put(`/crm/${props.selected._id}/complete`, {});
    props.onHide();
  }
  if (!props.selected) return <div />;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Grid.Row>
        <Grid.Col lg={12}>
          <Comment.List>
            <Comment
              name={`${props.selected.subject}`}
              className="query-subject"
              date={moment(props.selected.created).format("MMM Do YYYY")}
              text={`${props.selected.description}`}
              replies={
                <React.Fragment>
                  {comments &&
                    comments.map((comment) => (
                      <Comment.Reply
                        name={props.selected.assignedTo.fullname}
                        avatarURL="demo/faces/female/17.jpg"
                        text={comment}
                      />
                    ))}
                </React.Fragment>
              }
            />
          </Comment.List>
          {props.selected.status !== "closed" && (
            <Card.Header className="query-input">
              <Form.InputGroup>
                <Form.Input
                  type="text"
                  placeholder="Message"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <Form.InputGroup append>
                  <Button
                    icon="message-circle"
                    color="secondary"
                    onClick={() => submit()}
                  >
                    Add comment
                  </Button>
                </Form.InputGroup>
              </Form.InputGroup>
            </Card.Header>
          )}
        </Grid.Col>
      </Grid.Row>
      {props.selected.status !== "closed" && (
        <Modal.Footer>
          <Button
            variant="outline-danger"
            icon="check"
            color="success"
            onClick={() => markAsCompleted()}
          >
            Mark as completed
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default Query;
