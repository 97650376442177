import React from "react";
import { Grid, Card, Table, Button, Page, Dropdown, Badge } from "tabler-react";
import moment from "moment";
import RecordPurchaseForm from "./RecordPurchaseForm";
import { isAdmin } from "../../authHelper";
import useDataFetchWithPusher from "../../effects/useDataFetchWithPusher";
import { softDelete } from "../../httpUtils";
import PurchasesWidget from "./PurchasesReports/PurchasesWidget";
import Pagination from "../../Utils/Pagination";
import { put } from "../../httpUtils";
import { formatNoCurrency } from "../../MoneyFormatter";
import { showConfirmation } from "../../Platform/events";
import AmountDisplay from "../../Platform/AmountDisplay";
function PurchasesDashboard(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const { loading, results: purchases } = useDataFetchWithPusher(
    `/inventory/purchases?offset=${offset}&&limit=10`,
    ["purchased-item-created", "sold-item-created"],
    offset
  );

  return (
    <Page.Content className="page-content" title="Purchases">
      <RecordPurchaseForm show={modalShow} onHide={() => setModalShow(false)} />
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>Recent Purchases</Card.Title>
              <Card.Options>
                <Button
                  color="primary"
                  size="md"
                  onClick={() => setModalShow(true)}
                >
                  <i className="fe fe-plus-circle mr-2"></i>Record Purchase
                </Button>

                <PurchasesWidget />
              </Card.Options>
            </Card.Header>

            <Table
              responsive
              className="card-table table-vcenter text-nowrap"
              headerItems={[
                { content: "Order No." },
                { content: "Item name" },
                { content: "Quantity" },
                { content: "Supplier" },
                { content: "Order Date" },
                { content: "Amount Paid" },
                { content: "Amount Due" },
                { content: "Status" },
                {},
              ]}
            >
              <Table.Body>
                {loading && !purchases.items && (
                  <Table.Row>
                    <Table.Col colSpan="4">Loading...</Table.Col>
                  </Table.Row>
                )}
                {!loading && purchases.items && !purchases.items.length && (
                  <Table.Row>
                    <Table.Col colSpan="4">No results found</Table.Col>
                  </Table.Row>
                )}
                {purchases.items &&
                  purchases.items.map((i) => (
                    <Table.Row>
                      <Table.Col>{i.orderNumber}</Table.Col>
                      <Table.Col>{i.itemName}</Table.Col>
                      <Table.Col>{formatNoCurrency(i.quantity)}</Table.Col>
                      <Table.Col>{i.supplierName}</Table.Col>
                      <Table.Col>
                        {moment(i.created).format("MMM Do YYYY")}
                      </Table.Col>
                      <Table.Col>
                        <AmountDisplay amount={i.paid} />
                      </Table.Col>
                      <Table.Col>
                        <AmountDisplay amount={getAmountDue(i)} />
                      </Table.Col>
                      <Table.Col>
                        {i.delivered === true ? (
                          <span>
                            <Badge color="success" className="mr-1 badges ">
                              Delivered
                            </Badge>
                          </span>
                        ) : (
                          <Badge color="warning" className="mr-1 badges ">
                            Not delivered
                          </Badge>
                        )}
                      </Table.Col>
                      <Table.Col>
                        <Dropdown
                          trigger={
                            <Dropdown.Trigger
                              icon="more-horizontal"
                              toggle={false}
                            />
                          }
                          position="right"
                          triggerContent="Actions"
                          itemsObject={getDropDownItems(i)}
                        />
                      </Table.Col>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            <Pagination
              onPageChange={(offsetR) => {
                setOffset(offsetR);
              }}
              totalItemsCount={purchases.count || 0}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
}
function showMarkAsDelivered(item) {
  if (item.delivered) {
    return [];
  }
  return [
    {
      value: "Mark as delivered",
      icon: "fe fe-truck",
      onClick: function () {
        put(`/inventory/purchases/${item._id}/mark-as-delivered`, {});
      },
    },
    { isDivider: true },
  ];
}
function getAmountDue(i) {
  let amount = i.unitCost * i.quantity - i.paid;
  if (amount > 0) {
    return amount;
  }
  return 0;
}
function showDeleteButton(item) {
  if (isAdmin())
    return [
      {
        value: "Delete",

        icon: "fe fe-trash-2",
        onClick: function () {
          showConfirmation({
            text: "Are you sure you want to delete purchase?",
            onConfirm() {
              softDelete(`/inventory/purchases/${item._id}`);
            },
          });
        },
      },
    ];
  return [];
}
function getDropDownItems(i) {
  const items = [...showMarkAsDelivered(i), ...showDeleteButton(i)];
  if (items.length > 0) {
    return items;
  }
  return [
    {
      value: "No more actions",
      icon: "fe fe-alert-circle",
    },
  ];
}
export default PurchasesDashboard;
