import React, { useState } from "react";
import { Dropdown } from "tabler-react";
import PurchasesBySupplier from "./PurchasesBySupplier";
import PurchasesRequired from "./PurchasesRequired";
import MonthlyPurchases from "./MonthlyPurchases";
import PurchasesHistory from "./PurchasesHistory";
import DeletedPurchases from "./DeletedPurchases";
function PurchasesWidget() {
  const [visible, setVisible] = useState(null);
  return (
    <>
      <PurchasesBySupplier
        show={visible === "purchases-by-supplier"}
        onHide={() => setVisible(null)}
      ></PurchasesBySupplier>
      <PurchasesRequired
        show={visible === "purchases-required"}
        onHide={() => setVisible(null)}
      />
      <MonthlyPurchases
        show={visible === "monthly-purchases"}
        onHide={() => setVisible(null)}
      />
      <PurchasesHistory
        show={visible === "purchases-history"}
        onHide={() => setVisible(null)}
      />
      <DeletedPurchases
        show={visible === "deleted-purchases"}
        onHide={() => setVisible(null)}
      />
      <Dropdown
        type="button"
        toggle={false}
        color="primary"
        arrow
        triggerContent={
          <>
            <i className="fa fa-cube"></i> Reports{" "}
            <i className="fa fa-caret-down"></i>
          </>
        }
        itemsObject={[
          {
            value: "Purchases history report",
            onClick: () => setVisible("purchases-history"),
          },
          { isDivider: true },
          {
            value: "Purchases required list",
            onClick: () => setVisible("purchases-required"),
          },
          { isDivider: true },
          {
            value: "Purchases by supplier analysis",
            onClick: () => setVisible("purchases-by-supplier"),
          },
          { isDivider: true },
          {
            value: "Deleted Purchases",
            onClick: () => setVisible("deleted-purchases"),
          },
        ]}
      />
    </>
  );
}

export default PurchasesWidget;
