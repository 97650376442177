import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import Profile from "./Profile";
import Training from "./Training";
import Education from "./Education";
import { TabbedCard, Tab } from "tabler-react";
const EmployeeView = ({ profile, ...props }) => {
  console.log("profile :>> ", profile);
  if (!profile) {
    return <div></div>;
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          backgroundColor: profile.terminated.status === true ? "red" : "",
        }}
      >
        <Modal.Title>
          <h3
            style={{
              color: profile.terminated.status === true ? "white" : "",
            }}
            className="card-title"
          >
            Employee details
          </h3>
        </Modal.Title>
      </Modal.Header>
      <div className="no-border">
        <ModalBody>
          <TabbedCard initialTab="Profile">
            <Tab title="Profile">
              <Profile profile={profile} />
            </Tab>
            <Tab title="Education">
              <Education profile={profile} />
            </Tab>
            <Tab title="Training">
              <Training profile={profile} />
            </Tab>
          </TabbedCard>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default EmployeeView;
