import React from "react";
import { Modal } from "react-bootstrap";
import { Button, Table, Avatar } from "tabler-react";
import { getUser } from "../../../authHelper";
const MonthlyPurchases = (props) => {
  const { cooperative } = getUser();
  return (
    <Modal
      {...props}
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title></Modal.Title>
        <Button color="primary" icon="printer">
          Print
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="well col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <div>
                    <Avatar
                      size="xxl"
                      imageURL={cooperative.logo}
                      alt="Cooperative logo"
                    />
                  </div>
                  <h2>Monthly purchases report</h2>
                  <strong>From 01 Jan 2020 to 28 Jan 2020</strong>
                </div>
                <br />
                <Table
                  responsive
                  className="card-table table-vcenter text-nowrap"
                  headerItems={[
                    { content: "Date" },
                    { content: "Invoice No." },
                    { content: "Supplier" },
                    { content: "Amount" },
                  ]}
                >
                  <Table.Body>
                    <Table.Row>
                      <Table.Col>26th Jan 2020</Table.Col>
                      <Table.Col>00123</Table.Col>
                      <Table.Col>Grocery plus LTD</Table.Col>
                      <Table.Col>2,000,000</Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" icon="x-circle" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MonthlyPurchases;
