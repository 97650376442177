import React from "react";
import { Grid, StampCard } from "tabler-react";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
import { formatNoCurrency } from "../MoneyFormatter";
function InventorySummary() {
  const { results } = useDataFetchWithPusher("/hrm/statistics", [
    "employee-created",
    "payroll-created",
  ]);

  return (
    <React.Fragment>
      <Grid.Row cards={true}>
        <Grid.Col sm={6} lg={3}>
          <StampCard
            color="green"
            icon="users"
            header={
              <span>
                {calculateTotalPermanent(results)} <small>Permanent</small>
              </span>
            }
          />
        </Grid.Col>
        <Grid.Col sm={6} lg={3}>
          <StampCard
            color="blue"
            icon="users"
            header={
              <span>
                {results.temporary || 0} <small>Temporary</small>
              </span>
            }
          />
        </Grid.Col>

        <Grid.Col sm={6} lg={3}>
          <StampCard
            color="red"
            icon="dollar-sign"
            header={
              <span>
                {results.payOut ? formatNoCurrency(results.payOut) : 0}{" "}
                <small></small>
              </span>
            }
            footer="Monthly payout"
          />
        </Grid.Col>
      </Grid.Row>
    </React.Fragment>
  );
}

function calculateTotalPermanent(results) {
  let total = 0;
  if (results["open-ended"]) total = results["open-ended"];
  if (results.fixed) total = total + results.fixed;
  return total;
}
export default InventorySummary;
