import React, { useState } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../effects/useForm";
import { post } from "../httpUtils";
import fetchCustomers from "../Kyc/fetchCustomers";
import fetchEmployees from "../HRM/Employees/fetchEmployees";
import useNumberFetch from "../effects/useNumberFetch";
import { useToasts } from "react-toast-notifications";

function NewQueryForm(props) {
  const { addToast } = useToasts();
  const { results: nextNumber } = useNumberFetch("QRY");
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit } = useForm(submit);
  const { results: customers } = fetchCustomers();
  const { results: employees } = fetchEmployees();

  async function submit(data) {
    try {
      await post("/crm", data);
      addToast("Query Submitted Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Create query</Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>

        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet className="New query">
            <Grid>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Subject">
                    <Form.Input
                      placeholder="Short description of the issue"
                      name="subject"
                      type="string"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Customer"
                    name="customer"
                    onChange={handleChange}
                  >
                    <option>Select customer</option>
                    {customers.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Select
                    label="Employee"
                    name="assignedTo"
                    onChange={handleChange}
                  >
                    <option>Select employee</option>
                    {employees.map((item) => (
                      <option value={item._id}>{item.fullname}</option>
                    ))}
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Status">
                    <Form.Select name="status" onChange={handleChange}>
                      <option>Select Status</option>
                      <option value="pending">Pending</option>
                      <option value="closed">Closed</option>
                      <option value="expired">Expired</option>
                    </Form.Select>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Query ID">
                    <Form.Input
                      name="queryId"
                      onChange={handleChange}
                      value={nextNumber.number}
                      disabled
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group>
                    <Form.Textarea
                      label="Description"
                      name="description"
                      placeholder="Detailed description of the issue"
                      rows={8}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            {" "}
            <i className="fe fe-check mr-2"></i>
            Record Query
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default NewQueryForm;
