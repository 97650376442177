import React, { useState } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../effects/useForm";
import { post } from "../httpUtils";
import fetchCustomers from "../Kyc/fetchCustomers";
import { useToasts } from "react-toast-notifications";

function RecordTransactionForm(props) {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit } = useForm(submit);
  const { results: customers } = fetchCustomers();

  async function submit(data) {
    try {
      await post("/kyc/transactions", data);
      addToast("Transaction Recorded Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Record transaction</Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet className="Record Transaction">
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Customer"
                    name="customer"
                    onChange={handleChange}
                  >
                    <option>Select customer</option>
                    {customers.map((item) => (
                      <option value={item._id}>{item.name}</option>
                    ))}
                  </Form.Select>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Transaction ID">
                    <Form.Input
                      name="transactionId"
                      type="string"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Transaction type"
                    name="transactionType"
                    onChange={handleChange}
                  >
                    <option>Select Type</option>
                    <option>Sale</option>
                    <option>Purchase</option>
                  </Form.Select>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Date">
                    <Form.Input
                      name="date"
                      type="date"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={12}>
                  <Form.Group label="Amount">
                    <Form.Input
                      type="number"
                      name="amount"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            {" "}
            <i className="fe fe-check mr-2"></i>
            Record transactions
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RecordTransactionForm;
