import React, { useState } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../../effects/useForm";
import fetchProductCategories from "../Store/fetchProductCategories";
import fetchStores from "../Store/fetchStores";
import fetchSupplier from "../Purchases/effects/fetchSupplier";
import { post } from "../../httpUtils";
import useNumberFetch from "../../effects/useNumberFetch";
import SelectWithAdd from "../../Platform/SelectWithAdd";
import { useToasts } from "react-toast-notifications";
import SearchableSelectWithAdd from "../../Platform/SearchableSelectWithAdd";

function RecordPurchaseForm(props) {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, manualChange, handleSubmit, values } = useForm(submit);
  const { results: productCategories } = fetchProductCategories([], 0, 1000);
  const { results: stores } = fetchStores();
  const { results: suppliers } = fetchSupplier();
  const { results: nextNumber } = useNumberFetch("PO");
  const productsMap =
    productCategories.items &&
    productCategories.items.reduce((p, c) => {
      if (c) {
        p[c._id] = c;
      }
      return p;
    }, {});
  async function submit(data) {
    //validate
    try {
      await post("/inventory/purchases", data);
      addToast("Purchase Recorded Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>Register new purchase</Modal.Title>
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form.FieldSet>
            {errors.map((error) => (
              <Alert type="danger">{error}</Alert>
            ))}
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <SearchableSelectWithAdd
                    onChange={(e) => {
                      let product = productsMap[e.value];
                      manualChange({
                        name: "unitCost",
                        value: product?.cost || 0,
                      });
                      manualChange({
                        name: "item",
                        value: e.value,
                      });
                    }}
                    label="Item"
                    name="item"
                    options={
                      productCategories.items &&
                      productCategories.items.map((i) => ({
                        value: i._id,
                        display: i.name,
                      }))
                    }
                    defaultText="Select Item"
                    modalType="ProductCategoriesForm"
                  />
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Unit Cost">
                    <Form.Input
                      name="unitCost"
                      onChange={handleChange}
                      value={values.unitCost}
                      type="number"
                      disabled
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Purchase quantity">
                    <Form.Input
                      name="quantity"
                      onChange={handleChange}
                      type="number"
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <SearchableSelectWithAdd
                    onChange={(e) => {
                      manualChange({
                        name: "supplier",
                        value: e.value,
                      });
                    }}
                    label="Supplier"
                    name="supplier"
                    options={suppliers.map((i) => ({
                      value: i._id,
                      display: i.companyName,
                    }))}
                    defaultText="Select supplier"
                    modalType="SupplierForm"
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Batch number">
                    <Form.Input name="batchNumber" onChange={handleChange} />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Serial Number">
                    <Form.Input name="serialNumber" onChange={handleChange} />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Expiry date">
                    <Form.Input
                      type="date"
                      name="expiryDate"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Order number">
                    <Form.Input
                      name="orderNumber"
                      onChange={handleChange}
                      value={nextNumber.number}
                      disabled
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Bar Code">
                    <Form.Input
                      type="number"
                      name="barcode"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <SelectWithAdd
                    onChange={handleChange}
                    label="Store"
                    name="store"
                    options={stores.map((i) => ({
                      value: i._id,
                      display: i.name,
                    }))}
                    defaultText="Select Store"
                    modalType="StoreForm"
                  ></SelectWithAdd>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Delivery Date">
                    <Form.Input
                      name="deliveryDate"
                      type="date"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Delivery status"
                    name="delivered"
                    onChange={handleChange}
                  >
                    <option value="false">Select delivery status</option>
                    <option value="true">Delivered</option>
                    <option value="false">Not Delivered</option>
                  </Form.Select>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Product Origin">
                    <Form.Input
                      type="string"
                      name="origin"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Amount Paid">
                    <Form.Input
                      type="number"
                      name="paid"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            Record Purchase
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RecordPurchaseForm;
