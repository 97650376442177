import React, { useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { Avatar, Grid } from "tabler-react";
import formatMoney, { formatNoCurrency } from "../../MoneyFormatter";
import { getUser } from "../../authHelper";
import { calculateTotal } from "./SalesForm/SingleItem";
import ReactToPrint from "react-to-print";
const Receipt = (props) => {
  const componentRef = useRef();
  const { selected } = props;
  const { cooperative } = getUser();
  if (!selected) {
    return <div></div>;
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>#</Modal.Title>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" icon="printer">
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row printDiv" ref={componentRef}>
          <Grid>
            <Grid.Row>
              <Grid.Col>
                <address>
                  <strong>{cooperative.name}</strong>
                  <br />
                  {cooperative.address}
                  <br />
                  Kampala, Uganda
                  <br />
                  <span>Tel: (0702) 43-25-43 </span>
                </address>
              </Grid.Col>
              <Grid.Col className="text-center">
                <Avatar
                  size="xxl"
                  imageURL={cooperative.logo}
                  alt="Cooperative logo"
                />
              </Grid.Col>

              <Grid.Col>
                <div className="text-right pull-right">
                  <br />
                  <em>
                    Date: {moment(selected.date).format("MMMM Do , YYYY")}
                  </em>
                  <br />
                  <em>Receipt #: {selected.receiptNo}</em>
                  <br />
                  <em>TIN: {selected.customer.tin}</em>
                </div>
              </Grid.Col>
            </Grid.Row>
          </Grid>

          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h1>Receipt</h1>
              </div>

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Product</th>

                    <th className="text-center">Unit Price</th>
                    <th className="text-center">Quantity</th>
                    <th className="text-center">Discount</th>
                    <th className="text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {selected.items.map((item) => (
                    <tr>
                      <td className="col-md-9">
                        <h4>
                          <em>{item.item.name}</em>
                        </h4>
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        {formatNoCurrency(item.unitCost)}
                      </td>
                      <td className="col-md-1 text-center">{item.quantity}</td>
                      <td className="col-md-1 text-center">
                        {item.percentageDiscount}
                      </td>
                      <td className="col-md-1 text-center">
                        {formatNoCurrency(calculateTotal(item))}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>   </td>
                    <td>   </td>
                    <td className="text-right">
                      <p>
                        <strong>Subtotal: </strong>
                      </p>
                      <p>
                        <strong>Tax: </strong>
                      </p>
                    </td>
                    <td className="text-center" colSpan={2}>
                      <p>
                        <strong>{formatMoney(selected.total)}</strong>
                      </p>
                      <p>
                        <strong>UGX 0</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>   </td>
                    <td>   </td>
                    <td className="text-right">
                      <h4>
                        <strong>Total: </strong>
                      </h4>
                    </td>
                    <td className="text-center text-danger" colSpan={2}>
                      <h4>
                        <strong>{formatMoney(selected.total)}</strong>
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Receipt;
