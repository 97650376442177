import React from "react";
import { Grid, Card, Table, Button, Badge } from "tabler-react";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
import NewQueryForm from "./NewQueryForm";
import Query from "./Query";

function QueriesList(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [queryShow, setQueryShow] = React.useState(false);
  const [selectedItem, setSelected] = React.useState("");
  const { loading, results: customers } = useDataFetchWithPusher("/crm", [
    "query-created",
  ]);
  console.log("JOBA LOG : customers", customers);
  return (
    <Grid.Row>
      <Query
        show={queryShow}
        selected={selectedItem}
        onHide={() => setQueryShow(false)}
      />
      <NewQueryForm
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
      <Grid.Col width={12}>
        <Card>
          <Card.Header>
            <Card.Title>Customer Queries</Card.Title>
            <Card.Options>
              <Button
                color="primary"
                size="md"
                onClick={() => setModalShow(true)}
              >
                <i className="fe fe-plus-circle mr-2"></i> New Query
              </Button>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Query ID" },
              { content: "Customer" },
              { content: "Subject" },
              { content: "Assigned to" },
              { content: "Status" },
              {},
            ]}
          >
            <Table.Body>
              {loading && !customers.length && (
                <Table.Row>
                  <Table.Col colSpan="6">Loading...</Table.Col>
                </Table.Row>
              )}
              {!loading && !customers.length && (
                <Table.Row>
                  <Table.Col colSpan="6">No results found</Table.Col>
                </Table.Row>
              )}
              {customers.map((i) => (
                <Table.Row>
                  <Table.Col>{i.queryId}</Table.Col>
                  <Table.Col>{i.customer.name}</Table.Col>
                  <Table.Col>{i.subject}</Table.Col>
                  <Table.Col>{i.assignedTo.fullname}</Table.Col>
                  <Table.Col>
                    <React.Fragment>
                      {i.status === "pending" && (
                        <Badge color="danger" className="mr-1 badges ">
                          Pending
                        </Badge>
                      )}
                      {i.status === "closed" && (
                        <Badge color="success" className="mr-1 badges ">
                          Closed
                        </Badge>
                      )}
                      {i.status === "expired" && (
                        <Badge color="warning" className="mr-1 badges ">
                          Expired
                        </Badge>
                      )}
                    </React.Fragment>
                  </Table.Col>
                  <Table.Col>
                    <Button
                      icon="fe fe-eye"
                      color="secondary"
                      size="sm"
                      onClick={() => {
                        setSelected(i);
                        setQueryShow(true);
                      }}
                      className="ml-2"
                    >
                      View query
                    </Button>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      </Grid.Col>
    </Grid.Row>
  );
}

export default QueriesList;
