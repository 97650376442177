import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { Button, Table, Avatar } from "tabler-react";
import ReactToPrint from "react-to-print";
import useDataFetchWithPusher from "../effects/useDataFetchWithPusher";
const UsersList = (props) => {
  const { loading, results: users } = useDataFetchWithPusher(
    `/users/cooperatives/${props.cooperative._id}/users`,
    []
  );

  const componentRef = useRef();

  return (
    <Modal
      {...props}
      dialogClassName="modal-65w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title></Modal.Title>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" icon="printer" className="pull-right">
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Modal.Header>
      <Modal.Body>
        <React.Fragment>
          <div className="row printDiv" ref={componentRef}>
            <div className="well col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="text-center">
                    <div>
                      <Avatar
                        size="xxl"
                        imageURL={props.cooperative.logo}
                        alt="Cooperative logo"
                      />
                    </div>
                    <h4>{props.cooperative.name}</h4>
                    <hr></hr>
                    <h3>User list</h3>
                  </div>
                  <br />
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap"
                    headerItems={[
                      { content: "Name" },
                      { content: "User type" },
                      { content: "Email" },
                    ]}
                  >
                    <Table.Body>
                      {loading && (
                        <Table.Row>
                          <Table.Col colSpan="6">Loading...</Table.Col>
                        </Table.Row>
                      )}
                      {!loading && users.items && !users.items.length && (
                        <Table.Row>
                          <Table.Col colSpan="6">No users found</Table.Col>
                        </Table.Row>
                      )}
                      {users.items &&
                        users.items.map((i) => (
                          <Table.Row key={i._id}>
                            <Table.Col>{i.fullname}</Table.Col>
                            <Table.Col>{i.type}</Table.Col>
                            <Table.Col>{i.email}</Table.Col>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <Button color="danger" icon="x-circle" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default UsersList;
