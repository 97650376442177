import React, { useState, useEffect } from "react";
import { Form, Grid, Alert } from "tabler-react";
import { Modal, Button } from "react-bootstrap";
import useForm from "../effects/useForm";
import { post, put } from "../httpUtils";
import { useToasts } from "react-toast-notifications";

function NewCustomerForm(props) {
  const { addToast } = useToasts();
  const [errors, setErrors] = useState([]);
  const { handleChange, handleSubmit, values, setInitialValues } =
    useForm(submit);
  const { customer } = props;
  useEffect(() => {
    if (
      typeof customer !== "undefined" &&
      customer !== null &&
      Object.keys(customer).length
    ) {
      setInitialValues(customer);
    }
    //eslint-disable-next-line
  }, [customer]);
  const isEdit =
    typeof customer !== "undefined" &&
    customer !== null &&
    Object.keys(customer).length;
  const currentCustomerValues = isEdit ? values : customer || {};
  async function submit(data) {
    //validate
    const { _id, ...formValues } = data;
    try {
      if (!isEdit) {
        await post(`/kyc`, formValues);
      } else {
        await put(`/kyc/${customer._id}`, formValues);
      }
      addToast("Customer Added Successfully", { appearance: "success" });
      props.onHide();
    } catch (e) {
      if (e.errors) {
        setErrors(e.errors);
      } else {
        setErrors(["An error occurred"]);
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {!customer || customer.name === undefined
              ? "Add new customer"
              : `Edit ${customer.name}`}
          </Modal.Title>{" "}
          <Button onClick={props.onHide} variant="outline-danger" size="sm">
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          {errors.map((error) => (
            <Alert type="danger">{error}</Alert>
          ))}
          <Form.FieldSet className="New customer">
            <Grid>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Name">
                    <Form.Input
                      name="name"
                      type="string"
                      onChange={handleChange}
                      value={currentCustomerValues.name}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Address">
                    <Form.Input
                      name="address"
                      type="string"
                      onChange={handleChange}
                      value={currentCustomerValues.address}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Group label="Telephone number">
                    <Form.Input
                      type="number"
                      name="tel"
                      onChange={handleChange}
                      value={currentCustomerValues.tel}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Date of birth">
                    <Form.Input
                      name="dob"
                      type="date"
                      onChange={handleChange}
                      value={currentCustomerValues.dob}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col width={6}>
                  <Form.Select
                    label="Identification Document"
                    name="identificationDocument"
                    onChange={handleChange}
                    value={currentCustomerValues.identificationDocument}
                  >
                    <option value="">Select ID</option>
                    <option value="National ID">National ID</option>
                    <option value="Driving permit">Driving permit</option>
                    <option value="Passports">Passport</option>
                  </Form.Select>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="ID Number">
                    <Form.Input
                      name="identificationNo"
                      type="string"
                      onChange={handleChange}
                      value={currentCustomerValues.identificationNo}
                    />
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={6}>
                  <Form.Group label="TIN">
                    <Form.Input
                      name="tin"
                      type="number"
                      onChange={handleChange}
                      value={currentCustomerValues.tin}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col width={6}>
                  <Form.Group label="Upload file">
                    <Form.FileInput></Form.FileInput>
                  </Form.Group>
                </Grid.Col>

                <Grid.Col width={12}>
                  <Form.Group label="E-mail address">
                    <Form.Input
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={currentCustomerValues.email}
                    />
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.FieldSet>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.onHide}>
            <i className="fe fe-x mr-2"></i>
            Close
          </Button>
          <Button type="submit" variant="outline-success">
            <i className="fe fe-check mr-2"></i>
            {!customer || customer.name === undefined
              ? `Add customer`
              : `Update customer`}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
export default NewCustomerForm;
