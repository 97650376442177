import React from "react";
import { Grid, Card, Table, Button, Page } from "tabler-react";
import PaginatedTable from "../../Platform/PaginatedTable";
import { showModal, showConfirmation } from "../../Platform/events";
import { registerGlobalModal } from "../../Platform/ModalManager";
import { softDelete } from "../../httpUtils";
import DepartmentForm from "./DepartmentForm";
registerGlobalModal("CreateDepartmentForm", DepartmentForm);
const Departments = () => {
  let renderRow = (i) => {
    return (
      <React.Fragment>
        <Table.Col>{i.name}</Table.Col>
        <Table.Col>{i.description}</Table.Col>
        <Table.Col>
          <Button
            color="danger"
            icon="fe fe-trash-2"
            className="ml-2 pull-right"
            size="sm"
            onClick={() => {
              showConfirmation({
                text: `Are you sure you want to delete ${i.name} ?`,
                onConfirm() {
                  softDelete(`/hrm/departments/${i._id}`);
                },
              });
            }}
          ></Button>{" "}
          <Button
            color="secondary"
            icon="fe fe-edit"
            className="ml-2 pull-right"
            size="sm"
            onClick={() => showModal("CreateDepartmentForm", { department: i })}
          >
            Edit
          </Button>
        </Table.Col>
      </React.Fragment>
    );
  };
  return (
    <Page.Content title="Departments" className="page-content">
      <Grid.Row>
        <Grid.Col width={12}>
          <Card>
            <Card.Header>
              <Card.Title>List of departments</Card.Title>
              <Card.Options>
                <Button
                  color="primary"
                  size="md"
                  onClick={() => showModal("CreateDepartmentForm", null)}
                >
                  <i className="fe fe-plus-circle mr-2"></i>Create Department
                </Button>
              </Card.Options>
            </Card.Header>
            <PaginatedTable
              columns={["Name", "Description", ""]}
              url="/hrm/departments"
              refetchEvents={["department-updated"]}
              renderRow={renderRow}
            />
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  );
};

export default Departments;
