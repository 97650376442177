import React, { useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { Avatar, Grid } from "tabler-react";
import ReactToPrint from "react-to-print";
const PerformanceReport = (props) => {
  const componentRef = useRef();
  const { selected: performance } = props;
  if (performance.employee == null) {
    return <div></div>;
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>Performance review</Modal.Title>
        <ReactToPrint
          trigger={() => (
            <Button color="primary" icon="printer">
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="row printDiv" ref={componentRef}>
          <div className="well col-md-12">
            <Grid>
              <Grid.Row>
                <Grid.Col>
                  <address>
                    <table>
                      <tbody>
                        <tr>
                          <td>Employee name: </td>
                          <td>
                            <strong>{performance.employee.fullname}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td> Department: </td>
                          <td>
                            <strong>
                              {performance.employee.department.name}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td> Employee number: </td>
                          <td>
                            <strong>{performance.employee.employeeNo}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td> Review performed by: </td>
                          <td>
                            <strong>{performance.performedBy.fullname}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </address>
                </Grid.Col>
                <Grid.Col className="text-center">
                  <Avatar
                    size="xxl"
                    imageURL={performance.employee.avatar}
                    alt="Cooperative logo"
                  />
                </Grid.Col>
                <Grid.Col>
                  <div className="text-right pull-right">
                    <p>
                      Date: {moment(performance.date).format("MMMM Do , YYYY")}
                    </p>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Grid>

            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <h3>Details</h3>
                </div>

                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Criteria</th>
                      <th>Score</th>
                      <th className="text-center">Target </th>
                    </tr>
                  </thead>
                  <tbody>
                    {performance.replies.map((reply) => (
                      <tr>
                        {reply.question.type === "score" && (
                          <td className="col-md-9">
                            <strong>{reply.question.question}</strong>
                          </td>
                        )}
                        <td
                          colSpan={reply.question.type === "score" ? 1 : 3}
                          className="col-md-1"
                          style={{
                            textAlign:
                              reply.question.type === "score"
                                ? "center"
                                : "left",
                          }}
                        >
                          {reply.question.type !== "score" && (
                            <span>
                              <strong>{reply.question.question}</strong>{" "}
                              <br></br>
                              <br></br>
                            </span>
                          )}

                          {reply.answer}
                        </td>
                        {reply.question.type === "score" && (
                          <td className="col-md-1 text-center">
                            {reply.question.target}
                          </td>
                        )}
                      </tr>
                    ))}
                    <tr>
                      <td className="col-md-1" style={{ textAlign: "right" }}>
                        <h4>
                          <strong>Total: </strong>
                        </h4>
                         
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <h4>
                          <strong> {performance.score}</strong>
                        </h4>
                         
                      </td>
                      <td className="col-md-1" style={{ textAlign: "center" }}>
                        <h4>
                          <strong> {}</strong>
                        </h4>
                         
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PerformanceReport;
